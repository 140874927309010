import Request from './Api';
import { ethers } from 'ethers';
import detectEthereumProvider from '@metamask/detect-provider';

const providerNetwork="homestead"; // rinkeby

// get og wallet info
const og = {

    get:async props=>{
        let user= await Request({
            url: "/web/og/"+props.wallet
        });
    
        return user;
    },
    mint:async props=>{
        const { REACT_APP_CONTRACT_ADDRESS } = process.env;

        const abi = [
            "function mint(uint256,tuple) payable returns (uint256)",
        ];

        let model = {
            data:null,
            error:[]
        }

        let coupon;
        if (props.coupon){
            coupon=[props.coupon.r,props.coupon.s,props.coupon.v];
        }


        //const provider = new ethers.providers.InfuraProvider(providerNetwork, REACT_APP_INFURA_PROJECT_ID);
        const provider = await detectEthereumProvider();

        if (provider) {
            try {
                const provider = new ethers.providers.Web3Provider(window.ethereum,null);
                try {
                    const signer = provider.getSigner();
                    const contract = new ethers.Contract(
                        REACT_APP_CONTRACT_ADDRESS,
                        abi,
                        signer
                    );

                    console.log("signer",signer);
                    console.log("contract",contract);


                    const wallet = await signer.getAddress();

                    console.log("wallet",wallet);

                    try {
                        const nonce = await provider.getTransactionCount(wallet,'latest');

                        console.log("nonce",nonce);


                        const mintContract = contract.connect(signer);
                        const res = await mintContract.mint(1,coupon,{
                            nonce: nonce, 
                            value: ethers.BigNumber.from(0).toHexString(),
                        });


                        console.log("res",res);

                        if (res){
                            model.data={
                                hash:res.hash
                            }
                        }
                    } catch(e) {
                        model.error.push(e.message);
                    }
                } catch(e){
                    model.error.push("Cannot connect to your wallet.");
                }
            } catch (e){
                model.error.push("Eth wallet not installed.");
            }
        }

        if (model.error.length<=0) model.error=null;

        return model;
    },
    
    minted:async props=>{
        const { REACT_APP_INFURA_PROJECT_ID, REACT_APP_CONTRACT_ADDRESS } = process.env;

        let model = {
            data:null,
            error:[]
        }

        const abi = [
            "function balanceOf(address,uint256) view returns (uint256)",
        ];
    
        const provider = new ethers.providers.InfuraProvider(providerNetwork, REACT_APP_INFURA_PROJECT_ID);
        const contract = new ethers.Contract(
            REACT_APP_CONTRACT_ADDRESS,
            abi,
            provider
        );

        try {
            const totalSupply = await contract.balanceOf(props.wallet,1);
                
            model.data={
                total:Number(totalSupply)
            }
    
        } catch(e) {
            model.error.push("There was an error reading the contract.");
        }

        if (model.error.length<=0) model.error=null;

        return model;
    },

    totalMinted:async ()=>{
        const { REACT_APP_INFURA_PROJECT_ID, REACT_APP_CONTRACT_ADDRESS } = process.env;

        let model = {
            data:null,
            error:[]
        }

        const abi = [
            "function totalSupply(uint256) view returns (uint256)",
        ];
    
        const provider = new ethers.providers.InfuraProvider(providerNetwork, REACT_APP_INFURA_PROJECT_ID);
        const contract = new ethers.Contract(
            REACT_APP_CONTRACT_ADDRESS,
            abi,
            provider
        );

        try {
            const totalSupply = await contract.totalSupply(1);
                
            model.data={
                total:Number(totalSupply)
            }
    
        } catch(e) {
            model.error.push("There was an error reading the contract.");
        }

        if (model.error.length<=0) model.error=null;

        return model;
    }
}

// get presale wallet info
const presale = async (props) => {

    let user= await Request({
        url: "/web/presale/"+props.wallet
    });

    return user;
}

const Users = {
    og, presale
}
export default Users;