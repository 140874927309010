import React,{useState,useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { AppBar,Toolbar,Container,Grid,LinearProgress,Stack,useMediaQuery} from '@mui/material';
import Console from './Console';

import styles from './Header.module.css';

import * as actions from '../../../store/actions';

export const Header = (props) =>{
    const dispatch=useDispatch();
    const mobile=useMediaQuery('(max-width:1200px)');
    const [consoleOpen,setConsoleOpen]=useState(false);
	
    const consoleToggleHandler = (state) => {
		if (state) setConsoleOpen(state);
		else setConsoleOpen(!consoleOpen);
	}	

    useEffect(()=>{
        dispatch(actions.consoleLoaded(mobile?true:false));
    },[dispatch,mobile]);

    return (
        <AppBar color="default" position="static" elevation={0} className={styles["header-bar"]}>
            <Container maxWidth={false}>
                <Toolbar disableGutters={true}>
				    <Grid container spacing={2} className={styles["header-bar-container"]}>
					    <Grid item xs sx={{paddingLeft:"0"}}>
                            <a href="/" className={styles.logo}>
                                <div><span>CHAINHOUNDS</span></div>
                            </a>
                        </Grid>
                        <Grid item lg={2} sx={{display:{xs:'none',lg:'block'}}}>
                            <Stack direction="row" spacing={1}>
                                <label>Sensors</label>
                                <LinearProgress classes={{root:styles["level-cyan"],span:styles["level-cyan"]}} variant="determinate" value={85} />
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <label>Encryption</label>
                                <LinearProgress classes={{root:styles["level-cyan"],span:styles["level-cyan"]}} variant="determinate" value={19} />
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <label>Shield</label>
                                <LinearProgress classes={{root:styles["level-cyan"],span:styles["level-cyan"]}} variant="determinate" value={75} />
                            </Stack>
                        </Grid>
                        <Grid item lg={2} sx={{display:{xs:'none',lg:'block'}}}>
                            <Stack direction="row" spacing={2}>
                                <label>Battery</label>
                                <LinearProgress classes={{root:styles.level,span:styles.level}} variant="determinate" value={80} />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <label>Heat</label>
                                <LinearProgress classes={{root:styles.level,span:styles.level}} variant="determinate" value={10} />
                            </Stack>
                            <Stack direction="row" spacing={2}>
                                <label>Network</label>
                                <LinearProgress classes={{root:styles.level,span:styles.level}} variant="determinate" value={1} />
                            </Stack>
                        </Grid>
					    <Grid item xs="auto">
                            <Console key={props.module} open={consoleOpen} toggle={consoleToggleHandler} />
                        </Grid>
                    </Grid>
                </Toolbar>
            </Container>
        </AppBar>
     );
}
