import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {Container,Grid,useMediaQuery} from '@mui/material';
import {Timeline,TimelineItem,TimelineSeparator,TimelineConnector,TimelineContent,TimelineOppositeContent,TimelineDot} from '@mui/lab';

import Breadcrumbs from '../../components/common/Breadcrumbs';
import * as actions from '../../store/actions';
import styles from './Roadmap.module.css';

export const Roadmap = () =>{
	const dispatch=useDispatch();	
	const mobile=useMediaQuery('(max-width:991px)');

	useEffect(()=>{
		dispatch(actions.console("roadmap"));
	},[dispatch]);


	return (
		<Container maxWidth={false} disableGutters className={styles.container}>
			<Grid container>
				<Grid item xs={12}>
					<Breadcrumbs id={2}/>
				</Grid>
			</Grid>
			<Grid container spacing={0}>
				<Grid item xs={12}>
					<Container className={styles.container} maxWidth="md">
						<h1 className={styles.title}>The Mission <span className={styles.dashes}>{"//"}</span></h1>
						<p>
							Our team's mission is to create the next generation of NFT gaming by making an innovative, 
							play-to-earn gaming experience using blockchain technology. 
						</p>
						<p>
							ChainHounds is at the intersection of four markets, each of which has strong growth potential: 
							NFTs, Waging, Gaming, and DeFi.
						</p>
					</Container>
					<br/>
					<Timeline position={mobile?'left':'alternate'}>
						<TimelineItem>
							<TimelineOppositeContent className={styles["tl-content"]} align="right">
								<ul className={styles.list}>
									<li>
										Community creation<br/>
										<span className={styles.small}>(Discord / Twitter / Instagram)</span>
									</li> 
									<li>Website development</li> 
									<li>Finish game development</li>
								</ul>
							</TimelineOppositeContent>
							<TimelineSeparator>
								<TimelineConnector className={styles.bgcolor} />
								<TimelineDot variant="outlined" className={styles.bordercolor}>
									<i className="far fa-check" />
								</TimelineDot>
								<TimelineConnector className={styles.bgcolor} />
							</TimelineSeparator>
							<TimelineContent className={styles["tl-content"]}>
								<h1 className={styles.title}>Phase 0</h1>
							</TimelineContent>
						</TimelineItem>
						
						<TimelineItem>
							<TimelineOppositeContent className={styles["tl-content"]}>
								<ul className={styles.list}>
									<li>Launch of website</li>
									<li>Branding</li>
									<li>Alpha testing</li>
									<li>Social media marketing</li>
								</ul>
							</TimelineOppositeContent>
							<TimelineSeparator>
								<TimelineConnector className={styles.bgcolor} />
								<TimelineDot variant="outlined" className={styles.bordercolor}>
									<i className="far fa-check" />
								</TimelineDot>
								<TimelineConnector className={styles.bgcolor} />
							</TimelineSeparator>
							<TimelineContent className={styles["tl-content"]}>
								<h1 className={styles.title}>Phase 1</h1>
							</TimelineContent>
						</TimelineItem>

						<TimelineItem>
							<TimelineOppositeContent className={styles["tl-content"]} align="right">
								<ul className={styles.list}>
									<li>Launch of NFT collection</li>
									<li>Beta game play</li>
									<li>Launch full P2E season 1</li>
									<li>Launch community monthly tournaments</li>
									<li>Charity donation program</li>
									<li>Acquire a crypto friendly lawyer on retainer</li>
									<li>Development of wagering racing <span className={styles.asterisk}>*</span></li>
								</ul>
								<p className={styles.small}><span className={styles.asterisk}>*</span> If on main-net owners will be responsible for paying gas fees.</p>
							</TimelineOppositeContent>
							<TimelineSeparator>
								<TimelineConnector />
								<TimelineDot variant="outlined">
									<i className="far fa-code" />
								</TimelineDot>
								<TimelineConnector />
								</TimelineSeparator>
								<TimelineContent className={styles["tl-content"]}>
									<h1 className={styles.title}>Phase 2</h1>
								</TimelineContent>
						</TimelineItem>

						<TimelineItem>
							<TimelineOppositeContent className={`${styles["tl-content"]} ${styles.blur}`}>
								<ul className={styles.list}>
									<li>3D-Model development</li>
									<li>Hire additional game developers</li>
									<li>Begin Unity game development</li>
									<li>Alpha/Beta testing for wagering racing</li>
								</ul>
							</TimelineOppositeContent>
							<TimelineSeparator>
								<TimelineConnector />
								<TimelineDot variant="outlined">
									<i className="far fa-code" />
								</TimelineDot>
								<TimelineConnector />
							</TimelineSeparator>
							<TimelineContent className={styles["tl-content"]}>
								<h1 className={styles.title}>Phase 3</h1>
							</TimelineContent>
						</TimelineItem>

						<TimelineItem>
							<TimelineOppositeContent className={`${styles["tl-content"]} ${styles.blur}`} align="right">
								<ul className={styles.list}>
									<li>Launch wagering game mode.</li>
									<li>Alpha testing for Unity built platform</li>
									<li>Partnerships with metaverse casinos <span className={styles.asterisk}>*</span></li>
								</ul>
								<p className={styles.small}><span className={styles.asterisk}>*</span> Subject to change based of legality and current state of crypto laws.</p>
							</TimelineOppositeContent>
							<TimelineSeparator>
								<TimelineConnector />
								<TimelineDot variant="outlined">
									<i className="far fa-code" />
								</TimelineDot>
								<TimelineConnector />
								</TimelineSeparator>
								<TimelineContent className={styles["tl-content"]}>
									<h1 className={styles.title}>Phase 4</h1>
								</TimelineContent>
						</TimelineItem>
					</Timeline>
				</Grid>
			</Grid>
		</Container>
	);

}