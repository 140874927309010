import React, { useState,useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {Container,Grid} from '@mui/material';

import Menu from '../../components/Menu';
import Breadcrumbs from '../../components/common/Breadcrumbs';
import Parts from './Parts';
import Levels from './Levels';
import Hacks from './Hacks';


import * as actions from '../../store/actions';
import styles from './Game.module.css';

const faq={
	name:"Game",
	items:[
		{
			id:99,
			key:0,
			name:"Intro",
			description:
				<>
					<h2 className={styles.title}>What is an NFT?</h2>
					<p>
						An NFT (non-fungible token) is a unique digital asset stored on the blockchain as part of the Ethereum network. 
						Each one is unique, and cannot be edited or duplicated. An NFT will contain some digital information, 
						such as a photo, audio file, video, or just data.
					</p>
					<h2 className={styles.title}>What is a ChainHound?</h2>
					<p>
						ChainHounds are digtal representation of robotic greyhound dogs. Greyhounds are a tall, slender breed 
						with keen sight and capable of high speed, used since ancient times for hunting small game and now chiefly 
						in racing and coursing.
					</p>
					<h2 className={styles.title}>What is a ChainHound NFT?</h2>
					<p>
						A ChainHound NFT is what you need to participate in races. In ChainHounds, you can race your hound against
						other players' hounds. Each hound has an equal chance to win each race it enters.
						Unlike other NFT racing games, NFT traits and rarity will not influence the outcome of the race.
						We wanted to be fair across the board. We went this route to keep entry-level players on the same
						playing field as the veterans.
					</p>
					<h2 className={styles.title}>Visual Updates</h2>
					<p>
						Even though NFT data cannot be edited, using state-of-the-art technology we can visually
						update certain parts of your ChainHound's appearance when equipping items. These visual updates will not
						affect your NFT's rarity score and will be visible in-game and on secondary marketplaces. For example, you
						will not be able to update your ChainHound's color, but you may equip a different wiring set that comes in
						different colors.
					</p>
					<p>
						You may also change your ChainHound's name and even display another NFT you own on the led screen located
						in the ChainHound's torso.
					</p>
					<p>
						All of these updates will occur with gas-less transactions, meaning they will be free to holders.
					</p>
				</>
		},
		{
			id:1,
			key:1,
			name:`Game Play`,
			description:
				<>
					<p>
						Racing is the primary activity for your ChainHounds. Through racing, your ChainHound will compete for 
						prizes against 7 other hounds, so there will only be 8 hounds total per race.
					</p>
					<p>
						Races will take place in racetracks, and each racetrack will have its own difficulty and length. 
						The first ChainHound to reach the finish line will win the race.
					</p>
					<p>	
						Races will be divided by iterations which will occur every 7 to 15 seconds, and the overall duration of a race will not
						be greater than a couple of minutes. During the iteration, your ChainHound will query a smart contract on the Ethereum network 
						that will determine its current speed; it will also evaluate upgrades and penalties. 
						80% of each hound's speed will be determined by blockchain algorithms and the remaining 20% will come from equipped upgrades.
					</p> 
					<h2 className={styles.title}>Entering a race</h2>
					<p>
						Entry to races is limited to NFT holders. If you don't own a ChainHound NFT you may mint one during our minting periods, 
						or check open marketplaces such as Opeasea or Looksrare to obtain one.
					</p>
					<p>
						To begin, go to the game site at <a target="_blank" rel="noreferrer" href="https://game.chainhounds.com/">https://game.chainhounds.com/</a> and 
						connect the Ethereum wallet where you hold your ChainHounds.
					</p>
					<p>
						After you are logged in, go to the races section, it will display all the races currently available. 
						Then select the race you wish to enter and your chosen ChainHound for that race. 
						Note that races will not begin until 8 hounds have been entered.
					</p> 
					<p>
						You don't need to be present for each race you join. Races don't require any interaction on your part
						and you will be able to watch it any time later.
					</p>
				</>
		},
		{
			id:2,
			key:2,
			name:`Play-2-Earn`,
			description:
				<>
					<p>
						Placing in the top 3 positions of a race will earn tokens. 50 tokens for 1st place, 35 for 2nd, and 15 
						for 3rd.
					</p>
					<p>
						Tokens can also be earned through season rewards. These tokens can be used to purchase upgrade crates, 
						cosmetic crates, or hack crates, and in the future may be used as an entry fee for entering races with 
						larger prize pools. Tokens will have no financial value, and will ONLY be for in-game use through the 
						ChainHounds ecosystem.
					</p>
					<p>
						Upgrades, cosmetics, and hacks will be their own NFTs on the Polygon network, which can be applied to 
						your ChainHound to add bonuses, or in the case of cosmetics, to change the appearance of your hound. 
					</p>
					<p>
						Individual upgrades, cosmetics, hacks, or the crates that contain them, can also be traded through 
						secondary markets like Opensea or Looksrare.
					</p>
				</>
			},
		{
			id:8,
			key:3,
			name:"Levels",
			description:
				<>
					<p>
						Every time your ChainHound completes a race, no matter the finishing position, it will gain experience 
						points (XP). Earning a certain amount of XP will make your ChainHound level up. Once a level is reached, 
						XP is reset and the threshold to reach the next level will increase.
					</p>
					<p>
						Levels are based on track length, the longer the track the more XP your ChainHound will earn.
					</p>
				</>,
			extras:[<Levels/>]
		},
		{
			id:3,
			key:4,
			name:"Upgrades",
			description:
				<>
					<p>
						Upgrades are NFTs that can add small bonuses to your ChainHounds' racing ability.
					</p>
					<p>
						Many upgrade bonuses are triggered by specific events that can occur within a race. Some bonuses lower the 
						limitations of a ChainHound, and some interact with other upgrades. We have worked to ensure that the 
						bonuses from upgrades make up no more than 20% of a hound's speed, with the other 80% being determined by a 
						blockchain algorithm. This is purposely meant to give all ChainHounds a reasonable chance at victory in any 
						race. We are not fans of pay to win mechanics. 
					</p>
					<p>
						We considered real time and added mechanics to not overly punish people who have busy schedules. 
						You may also notice some bonuses only apply for hounds that are in lower places during a race, we designed 
						these upgrades with a "push from the back" mentality. This helps ensure that a race isn't over until it's 
						over. 
					</p>
				</>,
			extras:[<Parts/>]
		},
		{
			id:4,
			key:5,
			name:"Hackz",
			description:
				<p>
					Hackz are a special kind of upgrade. Each hound can only have 1 Hack equipped at a time. Once equipped a 
					Hack cannot be swapped for 24 hours. Each Hack also has its own real time cooldown that limits how often 
					it can be utilized.
				</p>,
			extras:[<Hacks/>]
		},
		{
			id:5,
			key:6,
			name:"Seasons",
			description:
				<>
					<p>
						Seasons are major events that take place several times a year and are considered our endgame content.
						Rewards such as tokens, upgrades, and cosmetic items will be acquired as your ChainHounds progress through 
						the season by completing races.
					</p>
					<p>
						Seasons are designed to allow players to complete them, even if they aren't able to race every day.
					</p>
					<p>
						Season 1 will start shortly after the game is released.
					</p>
				</>
		},
		{
			id:6,
			key:7,
			name:"Item Shop",
			description:
				<p>
					The item shop is where you cash in your tokens for crates. There are Part, Cosmetic, and Hack crates. 
					When opened, these crates give a random part, cosmetic, or hack item, in the form of an NFT, that you can 
					equip on your ChainHound or trade in secondary marketplaces.
				</p>
		},
		/*
		{
			id:7,
			key:8,
			name:"Alpha Pass",
			description:`
			<p>
				As part of our seasonal rewards, we will be giving out The ChainHounds Alpha Pass to holders who complete
				each season with the highest scores.
			</p>
			<p>
				There will be burning mechanics for passes that will be announced when Season 2 starts.
			</p>
			<video id='video-player' autoplay="autoplay" muted loop preload="metadata" width="100%">
				<source src="${require('../../assets/images/parts/alphapass.mp4').default}" type="video/mp4">
			</video>`,
		},
		*/
	]
}

export const Game = () =>{
	const dispatch=useDispatch();
	const [selected,setSelected]=useState();
	const [current,setCurrent]=useState();

	useEffect(()=>{
		dispatch(actions.console("game"));
	},[dispatch]);

	const clickHandler = (i) => {
		setSelected(i);
	};

	useEffect(()=>{
		if (!selected) setSelected(99);
		const c=faq.items.filter(a=>a.id===selected)?.[0];
		if (c) setCurrent(c);
	},[selected]);

	return (
		<Container maxWidth={false} disableGutters className={styles.container}>
			<Grid container>
				<Grid item xs={12}>
					<Breadcrumbs id={3}/>
				</Grid>
			</Grid>
			<Grid container spacing={0} maxWidth="md" sx={{marginX:"auto"}}>
				<Menu items={faq} click={clickHandler} selected={selected} />
				<Grid item xs={12} lg className={styles.description}>
					{selected>=0 && current && 
						<>
							<h1 className={styles.title}>{current?.name} <span className={styles.dashes}>{"//"}</span></h1>
							<div key={`game-content-desc-${current?.key}`}>
								{current?.description}
							</div>
							{current?.extras}
						</>
					}
				</Grid>
			</Grid>
		</Container>
	);

}