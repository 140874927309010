import React,{useState} from 'react';
import {Table,TableBody,TableCell,TableContainer,TableHead,TableRow,TablePagination,Paper,Stack} from '@mui/material';
import {Chart} from "react-google-charts";

import APIUtils from '../../../api/Utils';

import styles from './Levels.module.css';

export const Levels = () =>{
	const [levelsPage, setLevelsPage] = useState(0);
	const levelsPageChangeHandler = (e, newPage) => {
		setLevelsPage(newPage);
	};
  
	const rows=5;
	let graph_data=[["Level", "XP"]];
	let levels=[];
	let xpreq=0;
	for (let i=0;i<100;i++){
		const level2xp=APIUtils.levels.lvl2xp(i+1);
		graph_data.push([i+1,level2xp]);
		levels.push({lvl:i+1,xp1:level2xp,xp2:xpreq,boost:(i>0?(i*0.1).toFixed(1):0)+"%"});
		xpreq += level2xp;
	}

	return (
		<Stack direction="row">
			<TableContainer component={Paper} className={styles["xp-table"]}>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell>Level</TableCell>
							<TableCell>XP needed</TableCell>
							{/*<TableCell>XP required</TableCell>*/}
							<TableCell>Boost</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{levels.slice(levelsPage * rows, levelsPage * rows + rows).map((lvl,i)=>(
							<TableRow key={`faq-lvl-${i}`} sx={{"&:hover":{backgroundImage:"linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))"}}}>
								<TableCell>{lvl.lvl}</TableCell>
								<TableCell>{new Intl.NumberFormat().format(lvl.xp1)}</TableCell>
								{/*<TableCell>{new Intl.NumberFormat().format(lvl.xp2)}</TableCell>*/}
								<TableCell>{lvl.boost}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<TablePagination
					classes={{root:styles.pagination}}
					component="div"
					count={100}
					rowsPerPageOptions={false}
					rowsPerPage={rows}
					page={levelsPage}
					onPageChange={levelsPageChangeHandler}
				/>
			</TableContainer>
			<Chart
				chartType="LineChart"
				data={graph_data}
				width="100%"
				height="400px"
				options={{
					legend:"none",
					chart: {
						title: null,
						subtitle: null,
					},
					chartArea: {
						backgroundColor: {
						  fill: '#ffffff',
						  fillOpacity: 0.55
						},
					},					curveType: 'function',
					focusTarget: 'category',
					backgroundColor: { fill:'transparent' },
					hAxis:{
						gridlines:{
							color:'#e0e0e0',
							opacity:0.1
						},
						textStyle: { fontName: 'Tech',color:'#212121'}
					},
					vAxis:{
						gridlines:{
							color:'#e0e0e0',
							opacity:0.1
						},
						baselineColor:'#999999',
						textStyle: { fontName: 'Tech',color:'#212121'}
					},
					colors: ["#009688"],
					tooltip:{
						textStyle: { fontName: 'Tech'}
					}
				}}
			/>
		</Stack>

	);
}
