import React, { useState,useEffect } from 'react';
import { useDispatch } from 'react-redux';
//import {Mousewheel,Pagination,Navigation,Lazy,Autoplay,EffectCreative} from 'swiper';
//import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import {Container,Grid,ImageList,ImageListItem,useMediaQuery} from '@mui/material';

import Menu from '../../components/Menu';
import Breadcrumbs from '../../components/common/Breadcrumbs';

import * as actions from '../../store/actions';
import styles from './Lore.module.css';
/*
import 'swiper/swiper.min.css';
import './swiper.css';
import "swiper/modules/lazy/lazy.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/effect-creative/effect-creative.min.css";
*/

export const Lore = () =>{
	const dispatch=useDispatch();
	const mobile=useMediaQuery('(max-width:991px)');

	const [loreTitle,setLoreTitle]=useState();
	const [loreText,setLoreText]=useState();
	const [loreImages,setLoreImages]=useState();
	const [selected,setSelected]=useState();

	const factions={
		name:"Factions",
		items:[
			{
				id:"na",
				key:0,
				name:"NAS",
				secondary:"North America"
			},
			{
				id:"sa",
				key:1,
				name:"SAF",
				secondary:"South America"
			},
			{
				id:"eu",
				key:2,
				name:"Europa",
				secondary:"Europe"
			},
			{
				id:"asia",
				key:3,
				name:"Smagai Republic",
				secondary:"Asia"
			},
			{
				id:"aus",
				key:4,
				name:"Australia",
				secondary:"Australia"
			},
			{
				id:"af",
				key:5,
				name:"COA",
				secondary:"Africa"
			},
			{
				id:"me",
				key:6,
				name:"UIS",
				secondary:"Middle East"
			},
		]
	}

	const clickHandler=(type)=>{
		setSelected(type);
		switch (type){
			case "sa":
				setLoreTitle("South American Federation");
				setLoreImages([
					require('../../assets/images/faction-sa.png').default,
					"https://chainhounds.s3.amazonaws.com/lore/saf1.png",
					"https://chainhounds.s3.amazonaws.com/lore/saf2.png",
					"https://chainhounds.s3.amazonaws.com/lore/saf3.png",
					"https://chainhounds.s3.amazonaws.com/lore/saf4.png",
					"https://chainhounds.s3.amazonaws.com/lore/saf5.png",
					"https://chainhounds.s3.amazonaws.com/lore/saf6.png",
					"https://chainhounds.s3.amazonaws.com/lore/saf7.png",
					"https://chainhounds.s3.amazonaws.com/lore/saf8.png",
					"https://chainhounds.s3.amazonaws.com/lore/saf9.png",
					"https://chainhounds.s3.amazonaws.com/lore/saf10.png",
				]);
				setLoreText(
					<>
						<p>
                            Some would say Central and South America did best through civilization's collapse.
                            Many of the cities remain relatively intact, especially when compared with the
                            rest of the world. Although the spread of viral agents exacted a heavy toll on their
                            population, they have risen to become a powerhouse. Their lands are still rich with
                            natural resources and they have maintained a careful balance of industry, technology,
                            and conservation.
                        </p>
                        <p>
                            Survivors from around the globe have flocked to the region, as rumors spread of a
                            place mostly untouched by the hell unleashed upon the world. Although quite welcoming
                            to outsiders, they do expect a certain level of conformity from those who join them.
                        </p>
                        <p>
                            When the great council first convened, it was their representatives who made it clear
                            they would have no dealings with any who continued developing and producing biological
                            agents of war. They are the biggest trade partner for most regions and produce a large
                            share of the world's food supply. This leverage was a major factor in other regions' compliance.
                        </p>
					</>
				);
				break;
			case "asia":
				setLoreTitle("Smagai Republic");
				setLoreImages([					
					require('../../assets/images/faction-asia.png').default,
					"https://chainhounds.s3.amazonaws.com/lore/asia1.png",
					"https://chainhounds.s3.amazonaws.com/lore/asia2.png",
					"https://chainhounds.s3.amazonaws.com/lore/asia3.png",
					"https://chainhounds.s3.amazonaws.com/lore/asia4.png",
					"https://chainhounds.s3.amazonaws.com/lore/asia8.png",
					"https://chainhounds.s3.amazonaws.com/lore/asia9.png",
					"https://chainhounds.s3.amazonaws.com/lore/asia7.png",
					"https://chainhounds.s3.amazonaws.com/lore/asia5.png",
					"https://chainhounds.s3.amazonaws.com/lore/asia6.png",
				]);
				setLoreText(
					<>
						<p>
                            Asia is a completely different place than it was before the war.
                            India and China once boasted a third of the world's population.
                            Now they could be counted in the thousands. Hundreds of nuclear
                            weapons were used in the region. Billions died in a single day.
                        </p>
                        <p>
                            The entirety of the pacific coast, from Japan to New Zealand
                            was laid to waste. A few small villages are known to exist
                            in Indonesia and the Philippines. A far cry from the massive
                            cities of old. A few pockets in Tibet, Mongolia, and Siberia
                            were left untouched, but what the bombs didn't kill, 2 years of
                            nuclear winter did.
                        </p>
                        <p>
                            The areas of Nepal and Bhutan are where most survivors in the
                            region have accumulated. They have absorbed refugees from
                            all directions, and the only actual cities you'll find in most of
                            Asia is located there.
                        </p>
					</>
				);
				break;
			case "me":
				setLoreTitle("United Islamic States");
				setLoreImages([
					require('../../assets/images/faction-me.png').default,
					"https://chainhounds.s3.amazonaws.com/lore/uis1.png",
					"https://chainhounds.s3.amazonaws.com/lore/uis2.png",
					"https://chainhounds.s3.amazonaws.com/lore/uis3.png",
					"https://chainhounds.s3.amazonaws.com/lore/uis4.png",
				]);
				setLoreText(
					<>
						<p>
                            Being at the center of the world, the people of the middle east
                            suffered every possible calamity in the last war. Their cities
                            abandoned and reclaimed by the sands. Most survivors have reverted
                            to the pre-modern ways of their ancestors. Now nomadic groups travel
                            the region.
                        </p>
                        <p>
                            After centuries of corruption, exploitation, military
                            intervention, and idealistic violence, it is now a quiet place of
                            camels and sand. The groups that call the middle east home have little
                            interest in associating with the outside world outside of trade.
                        </p>
                        <p>
                            When delegates arrived at the great council to speak on their behalf,
                            they made it quite clear they wanted no part in any of it.
                            They are fiercely isolationists and believe the rest of the world's
                            meddling in their affairs cost them paradise. There are only a few
                            places, like Cairo, where outsiders are tolerated to conduct business.
                            Otherwise, the region is considered off-limits.
                        </p>
					</>
				);
				break;
			case "eu":
				setLoreImages([
					require('../../assets/images/faction-eu.png').default,
					"https://chainhounds.s3.amazonaws.com/lore/eu1.png",
					"https://chainhounds.s3.amazonaws.com/lore/eu2.png",
					"https://chainhounds.s3.amazonaws.com/lore/eu3.png",
					"https://chainhounds.s3.amazonaws.com/lore/eu4.png",
					"https://chainhounds.s3.amazonaws.com/lore/eu5.png",
					"https://chainhounds.s3.amazonaws.com/lore/eu6.png",
					"https://chainhounds.s3.amazonaws.com/lore/eu7.png",
					"https://chainhounds.s3.amazonaws.com/lore/eu8.png",
					"https://chainhounds.s3.amazonaws.com/lore/eu9.png",
					"https://chainhounds.s3.amazonaws.com/lore/eu10.png",
				]);
				setLoreTitle("Europa");
				setLoreText(
					<>
						<p>
                            The European region was the hardest hit in the last war by almost any measure. The Swiss
                            are the exception, they managed to survive in great numbers due to their neutrality,
                            the mountainous landscape of the country and the vast tunnel networks built pre-war.
                        </p>
                        <p>
                            Many attributes "reconnecting the world" to the swiss. They retained the technology
                            and knowledge necessary for satellite communication. They sent envoys all over the
                            planet to re-establish contact after the radiation levels subsided in Europe.
                        </p>
                        <p>
                            Switzerland was host to the first great council, continuing its long tradition
                            of hosting international peace talks. Their pre-war stockpiles of precious metals
                            and technology gave them quite a head start in rebuilding.
                        </p>
					</>
				)
				break;
			case "af":
				setLoreImages([
					require('../../assets/images/faction-coa.png').default,
					"https://chainhounds.s3.amazonaws.com/lore/coa1.png",
					"https://chainhounds.s3.amazonaws.com/lore/coa2.png",
					"https://chainhounds.s3.amazonaws.com/lore/coa4.png",
					"https://chainhounds.s3.amazonaws.com/lore/coa5.png",
					"https://chainhounds.s3.amazonaws.com/lore/coa3.png",
					"https://chainhounds.s3.amazonaws.com/lore/coa6.png",
					"https://chainhounds.s3.amazonaws.com/lore/coa7.png",
					"https://chainhounds.s3.amazonaws.com/lore/coa8.png",				
				]);
				setLoreTitle("Children of Africa");
				setLoreText(
					<>
						<p>
                            Africa, like much of the southern hemisphere, managed to avoid being
                            subject to extreme nuclear fallout. Also, and for unknown reasons,
                            many Africans were much more resistant to the viral strains that
                            killed so many elsewhere. They had by far the highest survival rates
                            in the world. Though they were not without their plights during
                            the period of the last war.
                        </p>
                        <p>
                            After the collapse of the U.N. and being mostly cut off from the rest of
                            the world for some time, Africa entered what is referred to as
                            "the age of warlords". As resources grew scarce, chaos ensued.
                            A few groups managed to acquire advanced drone technology, seizing
                            vast territories and unleashing death on their opposition.
                            In the end, the machines were perhaps too efficient in their
                            capabilities. A time came when no one wanted to be next in line
                            to hold power, as it meant almost certain death at the hands of these
                            killing machines.
                        </p>
                        <p>
                            The machines were programmed by default not to target children and in the end,
                            few adults remained alive. The Children of Africa saw the insanity
                            of it all and vowed to end this cycle of death. They united under a single flag,
                            they vowed to give their children an era of peace after decades of horror.
                        </p>
					</>
				)
				break;
			case "aus":
				setLoreImages([
					require('../../assets/images/faction-au.png').default,
					"https://chainhounds.s3.amazonaws.com/lore/au1.png",
					"https://chainhounds.s3.amazonaws.com/lore/au2.png",
					"https://chainhounds.s3.amazonaws.com/lore/au3.png",
					"https://chainhounds.s3.amazonaws.com/lore/au4.png",
					"https://chainhounds.s3.amazonaws.com/lore/au5.png",
					"https://chainhounds.s3.amazonaws.com/lore/au6.png",
					"https://chainhounds.s3.amazonaws.com/lore/au7.png",
				]);
				setLoreTitle("Australia");
				setLoreText(
					<>
						<p>In the End War, Australia's saving grace was it's size and remoteness.</p>
						<p>
							On E-day Sydney and a few of the other large east coast cities were 
							hit, but the other coasts remained mostly intact. The breakdown of 
							government and ensuing lack of necessities was hard on the 
							Australians, but for the most part they worked through it together. 
							Being a unified and isolated place saved them from many of the 
							atrocities and social upheavals faced in other regions.
						</p>
					</>
				);
				break;
			case "na":
			default:
				setLoreTitle("North American States");
				setLoreImages([
					require('../../assets/images/faction-na.png').default,
					"https://chainhounds.s3.amazonaws.com/lore/nas1.png",
					"https://chainhounds.s3.amazonaws.com/lore/nas2.png",
					"https://chainhounds.s3.amazonaws.com/lore/nas3.png",
					"https://chainhounds.s3.amazonaws.com/lore/nas4.png",
					"https://chainhounds.s3.amazonaws.com/lore/nas5.png",
					"https://chainhounds.s3.amazonaws.com/lore/nas6.png",
					"https://chainhounds.s3.amazonaws.com/lore/nas7.png",
					"https://chainhounds.s3.amazonaws.com/lore/nas8.png",
					"https://chainhounds.s3.amazonaws.com/lore/nas9.png",
					"https://chainhounds.s3.amazonaws.com/lore/nas10.png",
					"https://chainhounds.s3.amazonaws.com/lore/nas11.png",
					"https://chainhounds.s3.amazonaws.com/lore/nas12.png",
				]);
				setLoreText(
					<>
						<p>
							In North America it was the descendants "hill billies" and "preppers" who fared best.
							People who understood not only how to live off the land but also embraced new 
							technology. Most survivors were centered around the Rocky and Appalachian mountain 
							ranges.
						</p>
						<p>
							Personal freedom and democracy are held with high esteem, however, they are well aware of the 
							failures of the past, and are determined to build up their society without the inflated egos and 
							greed that led to so much suffering. 
						</p>
						<p>
							Unfortunatly for them, several other groups place much of the blame for the fate of the world on 
							their shoulders. Many of the weapons that decimated mankind originated in their hands.
						</p>
					</>
				);
				break;
		}
	}

	useEffect(()=>{
		clickHandler("na");
	},[]);

	useEffect(()=>{
		dispatch(actions.console("lore"));
	},[dispatch]);

	return (
		<Container maxWidth={false} disableGutters className={styles.container}>
			<Grid container>
				<Grid item xs={12}>
					<Breadcrumbs id={4}/>
				</Grid>
			</Grid>
			<Grid container spacing={0} maxWidth="md" sx={{marginX:"auto"}}>
				<Menu selected={selected} click={clickHandler} items={factions} />
				<Grid item xs={12} lg className={styles.description}>
					<h1 className={styles.title}>{loreTitle} <span className={styles.dashes}>{"//"}</span></h1>
					<div>
						{loreText}
					</div>
					<div className={styles["gallery-wrapper"]}>
						{loreImages &&
						<ImageList variant="masonry" cols={mobile?1:4} gap={mobile?0:8}>
							{loreImages?.map((item,i) => (
								<ImageListItem key={`lore-imge-${i}`}>
									<img src={item} alt={loreTitle} loading="lazy"/>
								</ImageListItem>
							))}
						</ImageList>
						}
					</div>
				</Grid>
			</Grid>
		</Container>
	);
}