import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loading: false,
    logged_in: false,
    redirect_path: '/',
    error:null,
    is_admin:false,
    id:0,               // user id
    balance:0,          // mower key balance
    update_balance:0,   // triggers an update
    tokens:0,           // nfts held
    discord:{
        id:null,
        user:null,
        avatar:null,
    },
    data:{
        wallet:null,
        balance:null,   // shitcoin balance
        provider:null,
        signer:null,
        og_coupon:null,
        presale_coupon:null,
    }
};
  
const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_TOKENS:
            return {
                ...state,
                tokens:action.data,
                loading: false,
            };
        case actionTypes.AUTH_UPDATE_BALANCE:
            return {
                ...state,
                update_balance:action.data,
                loading: false,
            };
        case actionTypes.AUTH_BALANCE: 
            return {
                ...state,
                balance:action.data,
                loading: false,
            };
        case actionTypes.AUTH_OG_COUPON: 
            return {
                ...state,
                data:{...state.data,og_coupon:action.data},
            };
        case actionTypes.AUTH_PRESALE_COUPON:
            return {
                ...state,
                data:{...state.data,presale_coupon:action.data},
            };
        case actionTypes.AUTH_SET_ID: 
            return {
                ...state,
                id:action.data.id,
                is_admin:action.data.is_admin,
                logged_in: true,
                loading: false,
            };
        case actionTypes.AUTH_DISCORD: 
            if (state.discord.id!==action.data.id){
                return {
                    ...state,
                    discord:action.data,
                    loading: false,
                };
            }
            return {...state,loading:false};
        case actionTypes.AUTH_WALLET:
            if (state.data.wallet!==action.data){
                return {
                    ...state,
                    data:{...state.data,wallet:action.data},
                    loading: false,
                };
            }
            return {...state,loading:false};
        case actionTypes.AUTH_DISCONNECT:
            return {
                ...state,
                data:{...state.data,wallet:null},
                loading: false,
            };
        case actionTypes.AUTH_START: 
            return {
                ...state,
                loading: true,
            };
        case actionTypes.AUTH_STOP: 
            return {
                ...state,
                loading: false,
            };
        case actionTypes.AUTH_SUCCESS:
            return {
                ...state,
                loading: false,
                logged_in: true,
                data: action.data,
            };
        case actionTypes.AUTH_FAIL:
            return {
                ...state,
                loading: false,
                logged_in: false,
                error: action.error,
                data:{
                    wallet:null,
                    balance:null,
                    provider:null,
                    signer:null
                }
            };
        case actionTypes.AUTH_LOGOUT:
            return state;
        case actionTypes.SET_AUTH_REDIRECT_PATH: 
            return {
                ...state,
                redirect_path: action.path,
            };
        default:
            return state;
    }
}

export default authReducer;