import React,{useState} from 'react';
import {List,ListItemButton,ListItemText,Collapse} from '@mui/material';


import styles from '../Parts/Parts.module.css';

export const Hacks = () =>{
	const [open, setOpen] = useState(false);
	
	const hacks=[
		{
			name: "High Voltage Capacitor",
			description:"",
			image: require('../../../assets/images/parts/charger.png').default
		},
		{
			name: "CPU Overclocking",
			description:"",
			image: require('../../../assets/images/parts/cpu.png').default
		},
		{
			name: "Cryo Cooling",
			description:"Cools below ambient temperature while protecting your system from condensation. This is the recommended mode for additional performance.",
			image: require('../../../assets/images/parts/cryo.png').default
		},
		{
			name: "Nano Bots",
			description:"",
			image: require('../../../assets/images/parts/nano-bot.png').default
		},
		{
			name: "EMP",
			description:"",
			image: require('../../../assets/images/parts/emp.png').default
		},
		{
			name: "Invisibility",
			description:"",
			image: require('../../../assets/images/parts/invisible.png').default
		}		
	]

	const clickHandler = (item) => {
		if (open===item) setOpen(false); 
		else setOpen(item);
	}

	return (
		<>
		<h2 class={styles.title}>Season 1 - Hackz</h2>
		<List aria-labelledby="hacks-list">
			{hacks.map((hack,i)=>(
				<>
					<ListItemButton onClick={()=>clickHandler(i)} className={open===i?styles.selected:null}>
						<ListItemText className={styles.label} primary={hack.name} />
						{open===i ? <i className="fal fa-chevron-up" /> : <i className="fal fa-chevron-down" />}
					</ListItemButton>
					<Collapse in={open===i} timeout="auto" unmountOnExit>
						<List component="div" disablePadding className={styles.description}>
							{hack.image &&
								<img src={hack.image} alt={hack.name} className={styles.image} />
							}
							{hack.description &&
								<p>{hack.description}</p>
							}
						</List>
					</Collapse>
				</>
			))}
	  	</List>
		</>
	);
}
