import * as actionTypes from './actionTypes';

export const console = (data) => {
    return {
        type: actionTypes.CONSOLE_MODULE,
        data:data
    };
};

export const consoleLoaded = (data) => {
    return {
        type: actionTypes.CONSOLE_LOADED,
        data:data
    };
};