import { ethers } from 'ethers';
import detectEthereumProvider from '@metamask/detect-provider';

// check if site is connected to metamask
const isSiteConnected = async () =>{
	return localStorage.getItem("_identity");
}

// wagmi format
const wagmiFormat = async (data) =>{
    //(+ethers.utils.formatEther(await user.getBalance())).toFixed(4)
    let model = {
        data:{
            wallet: data.account,
            balance: 0,
            provider: data.provider,
            signer: null,
            og_coupon:null,
            presale_coupon:null,
        },
        error:null
    }

    localStorage.setItem('_identity',data.account);

    window.ethereum.on('accountsChanged', (accounts)=> {
        if (accounts.length <=0) { // disconnect
            localStorage.removeItem("_identity"); 
            /*localStorage.removeItem("__identity");
            localStorage.removeItem("___identity");*/
        }
        window.location.reload();
    });

    window.ethereum.on('chainChanged', ()=> {
        window.location.reload();
    });

    return model;
}

// connects to metamask
const connect = async () =>{
    let model = {
        data:null,
        error:null
    }
    let user=null;
    
    const provider = await detectEthereumProvider();
    if (provider) {
        const provider = new ethers.providers.Web3Provider(window.ethereum,null);
        try {
            await provider.send("eth_requestAccounts", []);
            user = await signer(provider);
            const wallet = await user.getAddress();

            //const tokens=await getTokensByWallet(wallet);
            model.data={
                wallet: wallet,
                name: null,
                balance: (+ethers.utils.formatEther(await user.getBalance())).toFixed(4),
                provider: provider,
                signer: user,
                tokens:null,
                selected_tokens:null,
            }

            localStorage.setItem('_identity',wallet);

            window.ethereum.on('accountsChanged', (accounts)=> {
                if (accounts.length <=0) { // disconnect
                    localStorage.removeItem("_identity"); 
                    /*localStorage.removeItem("__identity");
                    localStorage.removeItem("___identity");*/
                }
                window.location.reload();
            });

            window.ethereum.on('chainChanged', ()=> {
                window.location.reload();
            });

        } catch(e) {
            model.error="There was an error connecting to your wallet.";
        }
    } else model.error="MetaMask is not installed.";

    return model;
}

// get tokens in the connected wallet
const getTokensByWallet = async (wallet,only_balance=0) =>{
    let model = {
        data:null,
        error:[]
    }

    const abi = [
        "function name() view returns (string)",
        "function symbol() view returns (string)",
        "function balanceOf(address) view returns (uint256)",
        "function baseTokenURI() view returns (string)",
        "function tokenOfOwnerByIndex(address,uint256) view returns (uint256)"
    ];

    const { REACT_APP_INFURA_PROJECT_ID, REACT_APP_CDAD_CONTRACT, REACT_APP_CMOM_CONTRACT  } = process.env;

    //const provider = new ethers.providers.EtherscanProvider(null, REACT_APP_ETHERSCAN_API_KEY);
    const provider = new ethers.providers.InfuraProvider("homestead", REACT_APP_INFURA_PROJECT_ID);

    for (let c=0;c<2;c++){
        const contractAddress=c===1?REACT_APP_CDAD_CONTRACT:REACT_APP_CMOM_CONTRACT;
        const contract = new ethers.Contract(
            contractAddress,
            abi,
            provider
        );

        try {
            const tokenName = await contract.name();
            const tokenSymbol = await contract.symbol();
            const tokenURI = await contract.baseTokenURI();
    
            let tokens=[];
            let tokenBalance = await contract.balanceOf(wallet); // get token balance for the wallet
            tokenBalance = Number(tokenBalance);
            
            if (only_balance===0){
                // get token ids
                for (let i = 0; i < tokenBalance; i++){
                
                    try {
                        let token = await contract.tokenOfOwnerByIndex(wallet,i);
                        token = Number(token);
    
                        // get token properties
                        try {
                            const res = await fetch(`https://ipfs.io/ipfs/${tokenURI.replace(/(^\w+:|^)\/\//,'')}${token}`);
                            const json = await res.json();
                            if (json.image) json.image=`https://ipfs.io/ipfs/${json.image.replace(/(^\w+:|^)\/\//,'')}`;
    
                            let tokenId = {
                                id: token,
                                player_token_id:null,
                                uri: tokenURI + token,
                                ...json
                            }
    
                            tokens.push(tokenId);
                        } catch(e) {
                            model.error.push(`There was an error fetching the properties of your token #${i+1}.`);
                        }
                    } catch(e) {
                        model.error.push(`There was an error fetching the ID of your token #${i+1}.`);
                    }
                }

                if (tokens.length>0){
                    if (!model.data) model.data=[];
                    model.data.push({
                        contract: contractAddress,
                        name: tokenName,
                        symbol: tokenSymbol,
                        balance: tokenBalance,
                        tokens: tokens,
                    });
                }
            } else {
                if (!model.data) model.data=[];
                model.data.push({
                    contract: contractAddress,
                    name: tokenName,
                    symbol: tokenSymbol,
                    balance: tokenBalance,
                    tokens: [],
                });
            }            
        } catch(e) {
            model.error.push("There was an error reading the contract.");
        }
    }

    //console.log(model);

    if (model.error.length<=0) model.error=null;

    return model;   
}

const getTokensById = async (atokens,type=0) =>{
    let model = {
        data:null,
        error:[]
    }

    // crypto dads contract
    const { REACT_APP_CDAD_CONTRACT, REACT_APP_CMOM_CONTRACT, REACT_APP_CDAD_IPFS, REACT_APP_CMOM_IPFS  } = process.env;

    const contractAddress = (type===0?REACT_APP_CDAD_CONTRACT:REACT_APP_CMOM_CONTRACT); 
    const tokenURI= (type===0?REACT_APP_CDAD_IPFS:REACT_APP_CMOM_IPFS);
    const tokenSymbol=(type===0?"DAD":"MOM");
    const tokenName=(type===0?"CryptoDads":"CryptoMoms");

    // get token properties
    let tokens=[];
    for (const id of atokens){
        try {
            const res = await fetch(`https://ipfs.io/ipfs/${tokenURI.replace(/(^\w+:|^)\/\//,'')}${id}`);
            const json = await res.json();
            if (json.image) json.image=`https://ipfs.io/ipfs/${json.image.replace(/(^\w+:|^)\/\//,'')}`;

            const tokenId = {
                id: id,
                uri: tokenURI + id,
                ...json
            }
            tokens.push(tokenId);
        } catch(e) {
            model.error.push(`There was an error fetching the properties of your token #${id}.`);
        }   
    }

    if (model.error.length<=0) model.error=null;

    if (tokens.length>0){
        model.data={
            contract: contractAddress,
            name: tokenName,
            symbol: tokenSymbol,
            balance: tokens.length,
            tokens: tokens,
        }
    }

    return model;   
}

const signer = async (provider) =>{
    let signer=null;
    if (provider) signer=await provider.getSigner();
    return signer;
}


const getCoinBalance=async(wallet,contractAddress)=>{
    if (!contractAddress) contractAddress = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"; // weth

    let model = {
        data:null,
        error:[]
    }

    const abi = [
        "function name() view returns (string)",
        "function symbol() view returns (string)",
        "function balanceOf(address) view returns (uint256)",
    ];

    const { REACT_APP_INFURA_PROJECT_ID } = process.env;
    const provider = new ethers.providers.InfuraProvider("homestead", REACT_APP_INFURA_PROJECT_ID);
    //const provider = new ethers.providers.EtherscanProvider(null, REACT_APP_ETHERSCAN_API_KEY);
    const contract = new ethers.Contract(
        contractAddress,
        abi,
        provider
    );

    try {
        const tokenName = await contract.name();
        const tokenSymbol = await contract.symbol();
        let tokenBalance = await contract.balanceOf(wallet); // get token balance for the wallet
        tokenBalance = Number(tokenBalance);
            
        model.data={
            contract: contractAddress,
            name: tokenName,
            symbol: tokenSymbol,
            balance: (+ethers.utils.formatEther(tokenBalance)).toFixed(4),
        }

        /*
        // listen to balance changes
        contract.on("Transfer", (from, to, amount, event) => {
            if (from === wallet || to === wallet){
                //getCoinBalance(wallet,contractAddress);
            }
        });        
        */
   
    } catch(e) {
        model.error.push("There was an error reading the coin contract.");
    }

    if (model.error.length<=0) model.error=null;

    return model;   
}

const MetaMask = {
    connect,isSiteConnected,signer,getTokensByWallet,getTokensById,getCoinBalance,wagmiFormat
}
 
export default MetaMask;