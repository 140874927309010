import React,{useState,useEffect} from 'react';
import {ReactComponent as HoundEar} from '../../../../assets/images/hound/ear.svg';
import {ReactComponent as HoundHead} from '../../../../assets/images/hound/head.svg';
import {ReactComponent as HoundTorso} from '../../../../assets/images/hound/torso.svg';
import {ReactComponent as HoundNeck} from '../../../../assets/images/hound/neck.svg';
import {ReactComponent as HoundAss} from '../../../../assets/images/hound/ass.svg';
import {ReactComponent as HoundTail1} from '../../../../assets/images/hound/tail-1.svg';
import {ReactComponent as HoundTail2} from '../../../../assets/images/hound/tail-2.svg';
import {ReactComponent as HoundTail3} from '../../../../assets/images/hound/tail-3.svg';
import {ReactComponent as HoundTail4} from '../../../../assets/images/hound/tail-4.svg';
import {ReactComponent as HoundTail5} from '../../../../assets/images/hound/tail-5.svg';
import {ReactComponent as HoundRearFrontLegTop} from '../../../../assets/images/hound/rear-front-leg-top.svg';
import {ReactComponent as HoundRearFrontLegMiddle} from '../../../../assets/images/hound/rear-front-leg-middle.svg';
import {ReactComponent as HoundRearFrontLegBottom} from '../../../../assets/images/hound/rear-front-leg-bottom.svg';
import {ReactComponent as HoundRearFrontLegFoot} from '../../../../assets/images/hound/rear-front-leg-foot.svg';
import {ReactComponent as HoundRearBackLegTop} from '../../../../assets/images/hound/rear-back-leg-top.svg';
import {ReactComponent as HoundRearBackLegMiddle} from '../../../../assets/images/hound/rear-back-leg-middle.svg';
import {ReactComponent as HoundRearBackLegBottom} from '../../../../assets/images/hound/rear-back-leg-bottom.svg';
import {ReactComponent as HoundRearBackLegFoot} from '../../../../assets/images/hound/rear-back-leg-foot.svg';
import {ReactComponent as HoundFrontLegTop} from '../../../../assets/images/hound/front-leg-top.svg';
import {ReactComponent as HoundFrontLegMiddle} from '../../../../assets/images/hound/front-leg-middle.svg';
import {ReactComponent as HoundFrontLegBottom} from '../../../../assets/images/hound/front-leg-bottom.svg';
import {ReactComponent as HoundFrontLegFoot} from '../../../../assets/images/hound/foot.svg';
import {ReactComponent as HoundBackLegTop} from '../../../../assets/images/hound/back-leg-top.svg';
import {ReactComponent as HoundBackLegMiddle} from '../../../../assets/images/hound/back-leg-middle.svg';
import {ReactComponent as HoundBackLegBottom} from '../../../../assets/images/hound/back-leg-bottom.svg';
import {ReactComponent as HoundBackLegFoot} from '../../../../assets/images/hound/foot.svg';

import styles from './Part.module.css';

export const Part = (props) =>{

    const [svgPart,setSvgPart]=useState();

    useEffect(()=>{
        const clickHandler = (e) =>{
            e.stopPropagation();
            props.click(e,props.tyle);
        }
    
        const extraStyles=`${styles.part} ${props.join?styles.join:null} ${props.active===props.type?styles.active:props.active?styles.dim:null}`;
        switch (props.type){
            case "ear/antenna":
                setSvgPart([<HoundEar key={props.type} className={`${styles.ear} ${extraStyles}`} onClick={clickHandler}/>]);
                break;
            case "head":
                setSvgPart([<HoundHead key={props.type} className={`${styles.head} ${extraStyles}`} onClick={clickHandler}/>]);
                break;
            case "chest/torso":
                setSvgPart([<HoundTorso key={props.type} className={`${styles.torso} ${extraStyles}`} onClick={clickHandler}/>]);
                break;
            case "neck":
                setSvgPart([<HoundNeck key={props.type} className={`${styles.neck} ${extraStyles}`} onClick={clickHandler}/>]);
                break;
            case "pelvic stabilizer":
                setSvgPart([<HoundAss key={props.type} className={`${styles.ass} ${extraStyles}`} onClick={clickHandler}/>]);
                break;
            case "tail":
                setSvgPart([<HoundTail1 key={`${props.type}-1`} className={`${styles["tail-1"]} ${extraStyles}`} onClick={clickHandler}/>,
                            <HoundTail2 key={`${props.type}-2`} className={`${styles["tail-2"]} ${extraStyles}`} onClick={clickHandler}/>,
                            <HoundTail3 key={`${props.type}-3`} className={`${styles["tail-3"]} ${extraStyles}`} onClick={clickHandler}/>,
                            <HoundTail4 key={`${props.type}-4`} className={`${styles["tail-4"]} ${extraStyles}`} onClick={clickHandler}/>,
                            <HoundTail5 key={`${props.type}-5`} className={`${styles["tail-5"]} ${extraStyles}`} onClick={clickHandler}/>]);
                break;
            case "hip gyro":
                setSvgPart([<HoundRearFrontLegTop key={`${props.type}-1`} className={`${styles["rear-front-leg-top"]} ${extraStyles}`} onClick={clickHandler}/>,
                            <HoundRearBackLegTop key={`${props.type}-2`} className={`${styles["rear-back-leg-top"]} ${extraStyles}`} onClick={clickHandler}/>]);
                break;
            case "mid leg":
                setSvgPart([<HoundRearFrontLegMiddle key={`${props.type}-1`} className={`${styles["rear-front-leg-middle"]} ${extraStyles}`} onClick={clickHandler}/>,
                            <HoundRearBackLegMiddle key={`${props.type}-2`} className={`${styles["rear-back-leg-middle"]} ${extraStyles}`} onClick={clickHandler}/>,
                            <HoundFrontLegMiddle key={`${props.type}-3`} className={`${styles["front-leg-middle"]} ${extraStyles}`} onClick={clickHandler}/>,
                            <HoundBackLegMiddle key={`${props.type}-4`} className={`${styles["back-leg-middle"]} ${extraStyles}`} onClick={clickHandler}/>]);
                break;
            case "high suspension":
                setSvgPart([<HoundRearFrontLegBottom key={`${props.type}-1`} className={`${styles["rear-front-leg-bottom"]} ${extraStyles}`} onClick={clickHandler}/>,
                            <HoundRearBackLegBottom key={`${props.type}-2`} className={`${styles["rear-back-leg-bottom"]} ${extraStyles}`} onClick={clickHandler}/>,
                            <HoundFrontLegBottom key={`${props.type}-3`} className={`${styles["front-leg-bottom"]} ${extraStyles}`} onClick={clickHandler}/>,
                            <HoundBackLegBottom key={`${props.type}-4`} className={`${styles["back-leg-bottom"]} ${extraStyles}`} onClick={clickHandler}/>]);
                break;
            case "high traction":
                setSvgPart([<HoundRearFrontLegFoot key={`${props.type}-1`} className={`${styles["rear-front-leg-foot"]} ${extraStyles}`} onClick={clickHandler}/>,
                            <HoundRearBackLegFoot key={`${props.type}-2`} className={`${styles["rear-back-leg-foot"]} ${extraStyles}`} onClick={clickHandler}/>,
                            <HoundFrontLegFoot key={`${props.type}-3`} className={`${styles["front-leg-foot"]} ${extraStyles}`} onClick={clickHandler}/>,
                            <HoundBackLegFoot key={`${props.type}-4`} className={`${styles["back-leg-foot"]} ${extraStyles}`} onClick={clickHandler}/>]);
                break;
            case "shoulder plate":
                setSvgPart([<HoundFrontLegTop key={`${props.type}-1`} className={`${styles["front-leg-top"]} ${extraStyles}`} onClick={clickHandler}/>,
                            <HoundBackLegTop key={`${props.type}-2`} className={`${styles["back-leg-top"]} ${extraStyles}`} onClick={clickHandler}/>]);
                break;
            default:
                setSvgPart(null);
                break;
        }

        return ()=>{
            setSvgPart(null);
        }
    },[props]);


	return (
        <>
            {svgPart && svgPart.map(part=>part)}
        </>
    ) 
}