export const AUTH_START = 'AUTH_START';
export const AUTH_STOP = 'AUTH_STOP';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_DISCONNECT = 'AUTH_DISCONNECT';
export const AUTH_DISCORD = 'AUTH_DISCORD';
export const AUTH_WALLET = 'AUTH_WALLET';
export const AUTH_WALLETS = 'AUTH_WALLETS';
export const AUTH_SET_ID = 'AUTH_SET_ID';
export const AUTH_BALANCE = 'AUTH_BALANCE';
export const AUTH_UPDATE_BALANCE = 'AUTH_UPDATE_BALANCE';
export const AUTH_TOKENS = 'AUTH_TOKENS';
export const AUTH_OG_COUPON = 'AUTH_OG_COUPON';
export const AUTH_PRESALE_COUPON = 'AUTH_PRESALE_COUPON';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const SNACKBAR_SHOW = 'SNACKBAR_SHOW';
export const SNACKBAR_CLEAR = 'SNACKBAR_CLEAR';

export const CONSOLE_MODULE = 'CONSOLE_MODULE';
export const CONSOLE_LOADED = 'CONSOLE_LOADED';