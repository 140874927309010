import React,{useEffect} from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
//import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Portal } from '@mui/base';
import CssBaseline from '@mui/material/CssBaseline';

import { configureChains, WagmiConfig, createClient, defaultChains } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { infuraProvider } from 'wagmi/providers/infura';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

import Header from './components/common/Header';
import Error from './components/common/Alerts/Error';
import Success from './components/common/Alerts/Success';

import Home from './containers/Home';
import Design from './containers/Design';
import Lore from './containers/Lore';
import Game from './containers/Game';
import Roadmap from './containers/Roadmap';
import Terms from './containers/Terms';

import * as actions from './store/actions';

import './assets/css/App.css';
import './assets/css/all.min.css';

const { REACT_APP_INFURA_PROJECT_ID } = process.env;
const { provider, webSocketProvider } = configureChains(
	defaultChains,
	[
		infuraProvider({ apiKey: REACT_APP_INFURA_PROJECT_ID }),
		publicProvider()
	],
)

const client = createClient({
	autoConnect: true,
	connectors: [
	  new MetaMaskConnector({ defaultChains }),
	  new CoinbaseWalletConnector({
		defaultChains,
		options: {
		  appName: 'ChainHounds',
		},
	  }),
	  new WalletConnectConnector({
		defaultChains,
		options: {
		  qrcode: true,
		},
	  }),
	  /*
	  new InjectedConnector({
		defaultChains,
		options: {
		  name: 'Injected',
		  shimDisconnect: true,
		},
	  }),
	  */
	],
	provider,
	webSocketProvider,
});

const App=()=> {
	const dispatch = useDispatch();
	//const userInfo = useSelector(state => state.auth);

	const theme = React.useMemo(() =>
		createTheme({
			palette: {
				mode: 'dark',
			},
			typography: {
				"fontFamily": `"Poppins", sans-serif`,
			}
		})
	,[]);

	// first run
	useEffect(() => {
		dispatch(actions.authCheckState());
	}, [dispatch]);

	const terminal=useSelector(state => state.console);
	/*
	switch(true){
		case window.location.toString().includes("design"):
			terminal_module="design";
			break;
		default:
			break;
	}*/

	return (
		<WagmiConfig client={client}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Header key={terminal.module}/>
				<main>
					<div className="bg"/>
					<Router>
						<Portal>
							<Error />
							<Success />
						</Portal>
						<Routes>
							<Route key="rtr-index" exact path="/" element={<Home/>} />
							<Route key="rtr-home" exact path="/home" element={<Home/>} />
							<Route key="rtr-design" exact path="/design" element={<Design/>} />
							<Route key="rtr-lore" exact path="/lore" element={<Lore/>} />
							<Route key="rtr-game" exact path="/game" element={<Game/>} />
							<Route key="rtr-lore" exact path="/roadmap" element={<Roadmap/>} />
							<Route key="rtr-terms" exact path="/terms" element={<Terms/>} />
						</Routes>
					</Router>
				</main>
			</ThemeProvider>
		</WagmiConfig>
	);
}
export default App;