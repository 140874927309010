import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {Breadcrumbs as MUIBreadcrumbs,Menu,MenuItem,Button} from '@mui/material';

import styles from './Breadcrumbs.module.css';

export const Breadcrumbs = (props) =>{
    const navigate=useNavigate();
    const [anchorEl, setAnchorEl] = useState();
    const open = Boolean(anchorEl);

    const pages=[
        {
            id:1,
            name:"Design",
            module:"design",
            url:"/design"
        },
        {
            id:2,
            name:"RD-Map",
            module:"roadmap",
            url:"/roadmap"
        },
        {
            id:3,
            name:"Game",
            module:"game",
            url:"/game"
        },
        {
            id:4,
            name:"Lore",
            module:"lore",
            url:"/lore"
        },
        {
            id:5,
            name:"ToS",
            module:"terms",
            url:"/terms"
        },
    ]    
    
    const clickHandler = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const closeHandler = (i) => {
        setAnchorEl(null);
        if (pages[i]) navigate(pages[i].url,{state:{module:pages[i].module}});
    }

	return (
        <>
            <MUIBreadcrumbs aria-label="navigation" classes={{root:styles.breadcrumbs,separator:styles.separator}} separator="//">
                <Button component="a"
                    id="breadcrumb-menu-button"
                    aria-controls={open ? 'breadcrumb-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={clickHandler}
                >
                    {pages.filter(a=>a.id===props.id)[0].name}
                </Button>
                <Button component={Link} to={{pathname: "/",state:{module:"home"}}}>Home</Button>
            </MUIBreadcrumbs>
            <Menu
                className={styles["menu-container"]}
                id="breadcrumb-menu"
                aria-labelledby="breadcrumb-menu-button"
                anchorEl={anchorEl}
                open={open}
                onClose={closeHandler}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {pages.map((page,i)=>(
                    page.id===props.id?null: 
                    <MenuItem key={`breadcrumb-item-${i}`} className={styles["menu-item"]} onClick={()=>closeHandler(i)}>{page.name}</MenuItem>
                ))}
            </Menu>
        </>
	);
}