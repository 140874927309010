import React,{useEffect, useRef, useState} from 'react';
import {Container,Grid,useMediaQuery/*,Box,Paper*/} from '@mui/material';

import Tiles from './Tiles';
import Hound from '../Design/Hound';

import dstyles from '../Design/Design.module.css';
import styles from './Home.module.css';

export const Home = () =>{
	const tileRef=useRef();
	const sortabig=useMediaQuery('(max-width:1600px)');
	const [boxSize,setBoxSize]=useState()

	useEffect(()=>{
		if (tileRef.current){
			setBoxSize({
				width: tileRef.current.offsetWidth,
				height: tileRef.current.offsetHeight,
			});
		}
	},[]);

	return (
		<Container maxWidth={false} disableGutters className={styles["home-container"]}>
			<Grid container>
				<Grid item xs={12} md={8} lg={9} xl={sortabig?9:true}>
					<Container maxWidth={"md"} className={`${styles["hound-wrapper"]} ${dstyles.blueprint}`}>
						<Hound />
						<Container maxWidth="auto" className={dstyles.legend}>
							<h1>ChainHound Schematics</h1>
							<span>Click any part to view its info.</span><br/>
							<span>Click outside the hound to assemble/disassemble it.</span><br/>
						</Container>
					</Container>
				</Grid>
			</Grid>
			<Tiles boxSize={boxSize} />
		</Container>
	);
}