import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {Container,Grid} from '@mui/material';

import Breadcrumbs from '../../components/common/Breadcrumbs';
import Hound from './Hound';

import * as actions from '../../store/actions';
import styles from './Design.module.css';

export const Design = () =>{
	const dispatch=useDispatch();

	useEffect(()=>{
		dispatch(actions.console("design"));
	},[dispatch]);

	return (
		<>
			<Container maxWidth={false} disableGutters className={styles.container}>
				<Grid container>
					<Grid item xs={12}>
						<Breadcrumbs id={1}/>
					</Grid>
				</Grid>
				<Grid container maxWidth="md" sx={{marginX:"auto"}} className={styles.blueprint}>
					<Grid item xs={12}>
						<Hound />
					</Grid>
					<Container maxWidth="auto" className={styles.legend}>
						<h1>ChainHound Schematics</h1>
						<span>Click any part to view its info.</span><br/>
						<span>Click outside the hound to assemble/disassemble it.</span><br/>
					</Container>
				</Grid>
			</Container>
		</>
	);
}