import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Alert,Snackbar,Button } from '@mui/material';

import * as actions from '../../../../store/actions';

import styles from './Error.module.css';

export const Error = () =>{
    const dispatch = useDispatch();
    const alert = useSelector(state => state.alert);
    
    const closeHandler = () => {
        dispatch(actions.snackbarClear());
        if (alert.callback) alert.callback();
    }
    
    const refreshHandler=()=>{
        window.location.reload(false);
    }

    return (
        <Snackbar open={alert.open && +alert.alert_type===0} autoHideDuration={6000} onClose={closeHandler} anchorOrigin={{vertical:"top",horizontal:"center"}}>
            <Alert severity="error" icon={false} variant="filled" onClose={closeHandler} className={styles["error-alert"]} sx={{ width: '100%' }}>
                <div dangerouslySetInnerHTML={{__html: alert.message}} />
                {alert.refresh_button && 
                    <Button classes={{root:styles["error-button"]}} onClick={refreshHandler} variant="outlined">Retry</Button>
                }
            </Alert>
        </Snackbar>
    );
}