import React from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline } from '@mui/material';
import { StylesProvider} from '@mui/styles';
import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import App from './App';
import authReducer from './store/reducers/auth';
import alertReducer from './store/reducers/alert';
import consoleReducer from './store/reducers/console';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
    auth: authReducer,
    alert: alertReducer,
    console: consoleReducer,
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

ReactDOM.render(
  <Provider store={store}>
    <StylesProvider injectFirst>
        <CssBaseline />
        <App />
    </StylesProvider>
  </Provider>,
  document.querySelector('#root'),
);