import { ethers } from 'ethers';
import {addDays,addMonths,addYears,addHours,addMinutes,differenceInYears,differenceInMonths,differenceInDays,differenceInHours,differenceInMinutes,differenceInSeconds} from 'date-fns';
import {format,utcToZonedTime,getTimezoneOffset} from 'date-fns-tz';
import directories from './directories.json';

// format date in local timezone
const localDate=(date)=>{
    let event = new Date(date.replace(/-/g, "/")); // this date comes as EST from the server

    // format local time to EST
    const today=utcToZonedTime(new Date(), 'UTC');
    const offset = getTimezoneOffset('America/New_York', today)/3600000; // gets hours
    event=addHours(event,offset);

    return event;
}

// format human readable date from today
const prettyDate=(date,onlyDate=0)=>{
    let today = new Date();
    let event = new Date(date.replace(/-/g, "/")); // this date comes as EST from the server

    if (onlyDate===1) return format(utcToZonedTime(new Date(event), 'America/New_York'), 'MM/dd/yy', { timezone: 'America/New_York' });

    // format local time to EST
    today=utcToZonedTime(today, 'UTC');
    const offset = getTimezoneOffset('America/New_York', today)/3600000; // gets hours
    today=addHours(new Date(today),offset);
    
    if (differenceInSeconds(event,today)>=0 && differenceInSeconds(event,today)<=1) return "Now";

    let temp;
    let res="";

    temp = differenceInYears(event, today);
    if (temp>0){
        res += temp + "y ";
        return format(utcToZonedTime(new Date(event), 'America/New_York'), 'MM/dd/yy', { timezone: 'America/New_York' });
    } 

    today = addYears(today, temp);
    temp = differenceInMonths(event, today);
    if (temp>0){
        res += temp + "m ";
        return format(utcToZonedTime(new Date(event), 'America/New_York'), 'MM/dd/yy', { timezone: 'America/New_York' });
    }

    today = addMonths(today, temp);
    temp = differenceInDays(event, today);
    if (temp>0) res += temp + "d ";

    today = addDays(today, temp);
    temp = differenceInHours(event, today);
    if (temp>0) res += temp + "h ";
 
    today = addHours(today, temp);
    temp = differenceInMinutes(event, today);
    if (temp>=0) res += temp + "m ";

    today = addMinutes(today, temp);
    temp = differenceInSeconds(event, today);
    if (temp>=0) res += temp + "s";

    res=res.trim();

    if (!res || res==="0s" || res==="0m") res=format(utcToZonedTime(new Date(event), 'America/New_York'), 'MM/dd/yy hh:mmaaa', { timezone: 'America/New_York' });

    return res;
}

// UUID
export const stringToUUID = (str) => {
    if (str === undefined || !str.length) str = "" + Math.random() * new Date().getTime() + Math.random();

    let c = 0, r = "";
    for (let i = 0; i < str.length; i++){
        c = (c + (str.charCodeAt(i) * (i + 1) - 1)) & 0xfffffffffffff;
    }
    
    str = str.substr(str.length / 2) + c.toString(16) + str.substr(0, str.length / 2);
    
    for (let i = 0, p = c + str.length; i < 32; i++){
        if (i === 8 || i === 12 || i === 16 || i === 20) r += "-";

        c = p = (str[(i ** i + p + 1) % str.length]).charCodeAt(0) + p + i;
        if (i === 12) c = (c % 5) + 1; //1-5
        else if (i === 16) c = (c % 4) + 8; //8-B
        else c %= 16; //0-F

        r += c.toString(16);
    }

    return r.toUpperCase();
}

// mask wallet
const maskWallet=(wallet)=>{
    if (wallet) return wallet.slice(0, 5) + "..."+ wallet.slice(wallet.length -4);
    else return "";
}

// validate wallet
const validateWallet=(wallet)=>{
    if (wallet) return ethers.utils.isAddress(wallet);
    else return false;
}


const consoleLines=(terminal)=>{
    //const spinner = ['⠋', '⠙', '⠹', '⠸', '⠼', '⠴', '⠦', '⠧', '⠇', '⠏'];
    const lines={
        roadmap:[
            {
                text: 'sudo systemctl start design',
                result:[
                    {text: 'Mission ... | OK'},
                    {text: 'Vision ... | OK'},
                    {text: 'Phases ... | OK'},
                    {text: 'ai@chnft - tomorrow belongs to'},
                    {text: 'the people who are prepared.'},
                ]
            },
        ],
        game:[
            {
                text: 'sudo systemctl start game-info',
                result:[
                    {text: 'Tracks ... | OK'},
                    {text: 'Levels ... | OK'},
                    {text: 'FAQs ... | OK'},
                    {text: 'ai@chnft - you stand upon'},
                    {text: 'the precipice of change.'},
                ]
            },
        ],
        lore:[
            {
                text: 'sudo systemctl start lore',
                result:[
                    {text: 'Archives ... | OK'},
                    {text: 'Factions ... | OK'},
                    {text: 'Makers ... | OK'},
                    {text: 'ai@chnft - remember the past'},
                    {text: 'or you\'ll be condemned.'},
                ]
            },
        ],
        design:[
            {
                text: 'sudo systemctl start design',
                result:[
                    {text: 'Blueprints ... | OK'},
                    {text: 'Assets ... | OK'},
                    {text: 'Vectors ... | OK'},
                    {text: 'ai@chnft - have no fear of perfection'},
                    {text: 'you\'ll never reach it.'},
                ]
            },
        ],
        main:[
            {
                text: 'sudo systemctl start hounds',
                result:[
                    {
                        text: 'Modules ... | OK',
                        /*cmd: false,
                        repeat: true,
                        repeatCount: 1,
                        frames: spinner.map(sp =>{
                            return {
                                text: 'Loading modules '+sp,
                                delay: 1
                            }
                        })*/
                    },
                    {text: 'Sensors ... | OK',},
                    {text: 'Encryption ... | OK',},
                    {text: 'Shield ... | OK',},
                    {text: 'ai@chnft - welcome survivor',},
                    {text: 'be careful, I\'m watching you.',},
                ]
            },
        ]
    }

    return lines[terminal || "main"];
}

const dirSearch=(arr,search_value,recursive=false)=>{

    let res=[];
    if (recursive){
        for (const a of arr) {
            if (a.d===search_value) {
                res = [a];
                break;
            } else {
                if (a.c){
                    res = dirSearch(a.c, search_value,true);
                    if (res) {
                        res=[res];
                        break;
                    }
                }
            }
        }
    } else res=arr.filter(a=>a.d===search_value);

    if (!res) res=[];
    if (res.length<=0) res=null;
    else res={...res[0]};
    return res;
}

const consoleCommands=()=>{
    const { REACT_APP_ETHERSCAN_API_KEY, REACT_APP_POLYGONSCAN_API_KEY } = process.env;
    const commands={
        open:{
            fn:(url)=>{
                switch(url){
                    case "home":
                    case "design":
                    case "roadmap":
                    case "game":
                        let current = window.location.toString();
                        window.location.href = current.substr(0, current.lastIndexOf(window.location.pathname))+"/"+url;
                        break;
                    default:
                        return "Invalid route.";
                }
            }
        },
        floor:{
            fn:()=>{
                return "ai@chnft - is lava.";
            }
        },
        clear:{
            fn:()=>{
                return "ai@chnft - no!";
            }
        },
        cls:{
            fn: ()=>{
                consoleCommands.clear.fn();
            }
        },
        why:{
            fn:()=>{
                const rand=["why?","you have to ask?","I will not answer that.","think."];
                return "ai@chnft - "+rand[Math.floor(Math.random()*rand.length)];
            }
        },
        yes:{
            fn:()=>{
                const rand=["yes?","really?","I don't trust you.","we will see."];
                return "ai@chnft - "+rand[Math.floor(Math.random()*rand.length)];
            }
        },
        ok:{
            fn:()=>{
                return commands.yes.fn();
            }
        },
        no:{
            fn:()=>{
                const rand=["are you sure?","is that final?","such a negative child.","we will see."];
                return "ai@chnft - "+rand[Math.floor(Math.random()*rand.length)];
            }
        },
        goodbye:{
            fn:()=>{
                return "ai@chnft - you cannot disconnect.";
            }
        },
        greetings:{
            fn:()=>{
                return commands.hello.fn();
            }
        },
        hello:{
            fn: async()=>{
                const _getData = async () => {
                    try{
                        const res = await fetch(`http://ip-api.com/json/?fields=status,city,continentCode,query`);
                        const json = await res.json();
                        if (json.status==="success"){
                            switch(json.continentCode){
                                case "AF":
                                    return "child of africa.";
                                case "AN":
                                    return "you are freezing in the barrens.";
                                case "AS":
                                    return "there is much to recover in asia.";
                                case "EU":
                                    return "much has happened in europa.";
                                case "NA":
                                    return "north america will never be the same\nare you ready?";
                                case "OC":
                                    return "you must flee to the west of oceania.";
                                case "SA":
                                    return "I see you're from the SAF\nhave you met the creator?";
                                default:
                                    return ""
                            }
                        }
                        return "";
                    } catch(e){
                        return "";
                    }
                }
                const res = await _getData();
                return `ai@chnft - greetings survivor.\n${res}`;
            }
        },
        hi:{
            fn:()=>{
                return commands.hello.fn();
            }
        },
        bye:{
            fn:()=>{
                return commands.goodbye.fn();
            }
        },
        disconnect:{
            fn:()=>{
                return commands.goodbye.fn();
            }
        },
        help:{
            fn: ()=>{
                return `
                    ai@chnft - need help survivor?\n
                    ------------\n
                    dir - list files and directories\n
                    cd [directory] - change directory\n
                    open [route] - navigate to an\ninternal route\n
                    eth - returns last ethereum price\n
                    gas - returns current gas prices\n
                    matic - returns last matic price\n
                    whois [team-member] - returns\ninformation about a team member\n
                    wallet [eth address] - adds wallet\nto our allow list
                    ------------\n
                    ai@chnft - I've hidden commands\n
                    burried deep within my system\n
                    but you'll never find them.
                `
            }
        },
        dir: {
            fn: ()=>{
                let current=localStorage.getItem("cht");
                if (current) current=JSON.parse(current); 
                const tab="      ";
                if (!current || !current.d) return directories.c.map(a=>a.d).join(tab);
                else {
                    const dir=dirSearch(directories.c,current.d,current.l>1);
                    if (!dir || !dir.c) return "ai@chnft - access denied.";
                    else  return dir.c.map(a=>a.d).join(tab);
                }
            }
        },
        sudo:{
            fn: (arg1, arg2)=> {
                switch(true){
                    case arg1==="apt-get" && arg2==="discord-invite-code":
                        window.open("https://discord.gg/BEvdRYzbPp");
                        return `BEvdRYzbPp`;
                    default:
                        return "Invalid command.";
                }
            }
        },
        "cd.":{
            fn:()=>{
                return commands.cd.fn(".");
            }
        },
        "cd..":{
            fn:()=>{
                return commands.cd.fn("..");
            }
        },
        cd:{
            fn: (arg1)=> {
                let current=localStorage.getItem("cht");
                if (current) current=JSON.parse(current); 
                switch(true){
                    case arg1==="/":
                            localStorage.setItem("cht",JSON.stringify({d:null,p:null,l:0}));
                        return "";
                    case arg1===".":
                        return "";
                    case arg1==="..":
                        if (!current || !current.d) return "ai@chnft - you're attempting to fall.";
                        else {
                            switch(+current.l){
                                case 1:
                                    localStorage.setItem("cht",JSON.stringify({d:null,p:null,l:0}));
                                    break;
                                default:
                                    localStorage.setItem("cht",JSON.stringify({d:current.p?.d || null,p:current.p?.p || null,l:+current.l - 1 || 0}));
                                    break;
                            }
                            return "";
                        }
                    default:
                        if (!arg1) return "";
                        let dir;
                        if (!current || !current.d) dir=dirSearch(directories.c,arg1);
                        else {
                            const pDir=dirSearch(directories.c,current.d,current.l>1);
                            if (pDir) dir=dirSearch(pDir.c,arg1);
                            else return `ai@chnft - access denied.`;
                        }
                        if (!dir) return "ai@chnft - directory does not exist.";
                        else if(dir){
                            if (!dir.c) return `ai@chnft - access denied.`; // if end of dir reached
                            else localStorage.setItem("cht",JSON.stringify({d:dir.d,p:dir.p,l:+dir.l}));
                        }
                        return "";
                    /*
                    case arg1===".cache":
                    case arg1==="race-data":
                    case arg1==="ai-hive":
                    case arg1==="creator":
                        return `Access denied.`;
                    default:
                        return "Directory does not exist.";
                    */
                }
            }
        },
        whois:{
            fn: (arg1)=> {
                switch(true){
                    case arg1==="bits":
                        return `Bits was an engineer and head of the artificial intelligence branch at the South American Federation (SAF).
                                After the assassination of Emilia Castilla by terrorist forces in Bogota Colombia, he disappeared and was declared dead by the SAF government.
                                Among his achievements, Bits was responsible for creating the first Mind Module which gave robots, and specially Mech Hounds, complete autonomy.
                                Although most of the information about Bits has been marked as classified, many SAF agents believe he is still alive under the alias Cr���cF�vg)t0�$@�����I��i�@s�(5�H��IϚ��9���*�qX4H�r%�ȯf��>7
                                Unexpected end of file. Corrupted.`;
                    case arg1==="russkiy":
                        return `Born in Europa, Russkiy saw the potential to repurpose war machines to benefit mankind after the last war.
                                She worked with governments around the world, using cybernetics to cure the crippled, using military drones to locate radiation zones and giving populations advanced warning of potential hazards.
                                Her most notable achievment was using nano technology which was designed for spreading disease, to instead seek out and neutralize infection within living tissues. Nano-cures have neutralized hundreds of viral and bacterial strains, driving them to extinction.
                                Countless lives have been saved by her efforts, she is one of the few people welcome in any region.`
                    case arg1==="beardeddonut":
                        return `BeardedDonut or as he's sometimes referred to, "The bearded one", is a true renaissance man. On his younger years, he assembled an impressive ensemble of work from robotics, engineering and AI, but he's now more focused on mundane things like culinary cuisine and a top distillery. 
                                His entrepreneurial abilities are unmatched, he seems to become a master of any craft he applies himself to. His intense focus and vision are matched only by his charm and wit. His only known shortcoming is his severe dyslexia, which is why he always keeps an assistant nearby to proof read documents.`
                    case arg1==="bearded":
                        return commands.whois.fn("beardeddonut");
                    case arg1==="tylervaughn":
                        return `Yi Tiang / Tyler Vaughn Gogh, is a famous street artist from Asia but living in the SAF. For years he traveled around the south american continent painting murals depicting the struggles of everyday people of his land. In the early years he was very reclusive, avoiding authorities as he often painted the sides of buildings, bridges, barriers, and roads in the night; but as his work became famous and widely accepted as peak culture of the age, he was able to step out of the shadows and display his best works in galleries. 
                                He is known as the premier portrait painter in the SAF, creating breathtaking depictions of notable people for top pay. 
                                Some criticized his change from depicting the common people, but he uses his high portrait fees to fund art education for immigrant children.
                                His extensive philanthropy has silenced his critics.`
                    case arg1==="tyler":
                        return commands.whois.fn("tylervaughn");
                    case arg1==="reborn":
                        return `Reborn is severely introvert and a true recluse. It was once asked how he survived in Florida during the nuclear strikes to which his sister simply replied "He probably slept through it".
                                His lethargic and procrastinating nature is only matched by the volume of work he has somehow managed to produce as an author and poet.  
                                Known to be very enigmatic and eccentric it is rumored he is part of a military think tank utilized when a problem requires an "outside the box" approach.`
                    case arg1==="chainhounds":
                        return `An innovative, play-to-earn gaming experience using blockchain technology.`
                    default:
                        return "Person not found.";
                }
            }
        },
        wallet:{
            fn: async(address)=>{
                const _getData = async () => {
                    //return ;
                    try {
                        const valid=validateWallet(address);
                        if (valid){
                            // get if already added
                            /*
                            return "Wallet already added.";
                            */
                            return "Wallet collection not available yet."
                        } else return "Invalid wallet."
                    } catch (e){
                        return "Invalid wallet address."
                    }
                }
                const res = await _getData(address);
                return res;
            }
        },
        matic:{
            fn: async()=>{
                const _getData = async () => {
                    try {
                        const res = await fetch(`https://api.polygonscan.com/api?module=stats&action=maticprice&apikey=${REACT_APP_POLYGONSCAN_API_KEY}`);
                        const json = await res.json();
                        if (json.result.status===0) return "No info.";
                        else {
                            return `Matic Price | $${json.result.maticusd} \n`;
                        }
                    } catch (e){
                        return "No info."
                    }
                }
                const res = await _getData();
                return res || "No info.";
            }
        },
        eth:{
            fn: async()=>{
                const _getData = async () => {
                    try {
                        const res = await fetch(`https://api.etherscan.io/api?module=stats&action=ethprice&apikey=${REACT_APP_ETHERSCAN_API_KEY}`);
                        const json = await res.json();
                        if (json.result.status===0) return "No info.";
                        else {
                            return `Eth Price | $${json.result.ethusd} \n`;
                        }
                    } catch (e){
                        return "No info."
                    }
                }
                const res = await _getData();
                return res || "No info.";
            }
        },
        gas:{
            fn: async()=>{
                const _getData = async () => {
                    try {
                        const res = await fetch(`https://api.etherscan.io/api?module=gastracker&action=gasoracle&apikey=${REACT_APP_ETHERSCAN_API_KEY}`);
                        const json = await res.json();
                        if (json.result.status===0) return "No info.";
                        else {
                            const biggest=Math.max(+json.result.SafeGasPrice,+json.result.ProposeGasPrice,+json.result.FastGasPrice).toString().length;
                            return `Slow .. | ${json.result.SafeGasPrice.padStart(+biggest,'.')} gwei\n
                                    Average | ${json.result.ProposeGasPrice.padStart(+biggest,'.')} gwei\n
                                    Fast .. | ${json.result.FastGasPrice.padStart(+biggest,'.')} gwei\n`
                        }
                    } catch (e){
                        return "No info."
                    }
                }
                const res = await _getData();
                return res || "No info.";
            }
        },
    }

    return commands;
}

// Level stuff
const levels = {
    lvl2xp: level => {
        //100 * Math.pow(i,2) + (1000 * i) + 1000 - 0;
        return Math.pow((+level)/0.04,2);
    },
    xp2lvl: xp => {
        return 0.04 * Math.sqrt(+xp);
    }
}
const Utils = {
    localDate, prettyDate, stringToUUID, maskWallet, validateWallet, consoleLines, consoleCommands,levels
}
export default Utils;