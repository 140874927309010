import React,{useState} from 'react';
import {List,ListItemButton,ListItemText,Collapse} from '@mui/material';


import styles from './Parts.module.css';

export const Parts = () =>{
	const [open, setOpen] = useState(false);
	
	const parts=[
		{
			name: "Battery pack",
			description: "This is the limiting factor on how many races a ChainHound can run in a 23 hour period. The 23 hour period starts when the ChainHound runs its first race of a day.",
			image: require('../../../assets/images/parts/battery.png').default
		},
		{
			name: "Propulsion system",
			description:"During a race, if a ChainHound stays in the same race position for certain amount of processing cycles, it receives a 5% speed bonus for the next two processing cycles. This bonus cannot activate on a ChainHound in first place.",
			image: require('../../../assets/images/parts/propulsion.png').default
		},
		{
			name: "Turbo Accelerator",
			description:"When the propulsion system upgrade activates, the turbo accelerator gives and additional 5% speed boost. This upgrade will only activate once per race.",
			image: require('../../../assets/images/parts/turbo.png').default
		},
		{
			name: "Hip gyro",
			description:"If you are in last place, and have not been in 1st or 2nd place for 6 previous processing cycles, your ChainHound will receive a 3% speed boost for the next two processing cycles.",
			image: require('../../../assets/images/parts/hip.png').default
		},
		{
			name: "Wiring",
			description:"Wiring limits how many different upgrade bonuses can occur within a single processing cycle. The ChainHound will always prioritize and activate the larger bonuses.",
			image: require('../../../assets/images/parts/wiring.png').default
		},
		{
			name: "ASC (Adaptive Suspension Control)",
			description:"This upgrade allows a ChainHound to better match the profile of the ChainHound directly in front of it (for better drafting). If the same ChainHound has been in front of your ChainHound for 3 processing cycles, your ChainHound gains a 1% speed bonus. This bonus will last until your ChainHound has passed the ChainHound directly in front of it.",
			image: require('../../../assets/images/parts/suspension.png').default
		},
		{
			name: "CPU",
			description:"The CPU upgrade determines how many total bonuses a ChainHound can receive during the course of a race. Season 1 CPU allows 6 bonuses throughout a single race.",
			image: require('../../../assets/images/parts/cpu-part.png').default
		},
		{
			name: "Articulated traction control",
			description:"ChainHounds at the back of the pack gain a 0.2% speed advantage due to following the beaten path. This bonus only applies to chainhounds in 4th through 8th place.",
			image: require('../../../assets/images/parts/traction.png').default
		}
	]

	const clickHandler = (item) => {
		if (open===item) setOpen(false); 
		else setOpen(item);
	}

	return (
		<>
		<h2 class={styles.title}>Season 1 - Upgradable parts</h2>
		<List aria-labelledby="parts-list">
			{parts.map((part,i)=>(
				<>
					<ListItemButton onClick={()=>clickHandler(i)} className={open===i?styles.selected:null}>
						<ListItemText className={styles.label} primary={part.name} />
						{open===i ? <i className="fal fa-chevron-up" /> : <i className="fal fa-chevron-down" />}
					</ListItemButton>
					<Collapse in={open===i} timeout="auto" unmountOnExit>
						<List component="div" disablePadding className={styles.description}>
							<p>{part.description}</p>
							{part.image &&
								<img src={part.image} alt={part.name} className={styles.image} />
							}
						</List>
					</Collapse>
				</>
			))}
	  	</List>
		</>
	);
}
