import React,{useState,useEffect} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import Terminal from 'react-console-emulator';
import Typist from 'react-typist';
import {Box,Drawer,useMediaQuery,IconButton} from '@mui/material';

import APIUtils from '../../../../api/Utils';
import * as actions from '../../../../store/actions';

import styles from './Console.module.css';
import './terminal.css';

export const Console = (props)=>{
    const dispatch=useDispatch();
    const mobile=useMediaQuery('(max-width:1200px)');

    const [showInfo,setShowInfo]=useState(false);
    const [showLineResult,setShowLineResult]=useState();
    const drawerWidth = mobile?300:400;
    
    const terminal = useSelector(state => state.console);
    const lines=APIUtils.consoleLines(terminal.module);
    
    const showInfoHandler=()=>{
        setShowInfo(true);
        dispatch(actions.consoleLoaded(true));
    }

    useEffect(() => {
        setShowLineResult(null);
        setShowInfo(false);
        //dispatch(actions.consoleLoaded(false));
    }, [dispatch]);

    return (
        <>
        <IconButton classes={{root:styles["menu-button"]}} onClick={props.toggle}><i className="far fa-bars"/></IconButton>
        <Drawer
            sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                border:0
            },
            }}
            variant={mobile?"temporary":"persistent"}
            anchor="right"
            classes={{paper:styles["console-wrapper"]}}
            open={mobile?props.open:true}
            onClose={()=>props.toggle(false)}
            >
            <div className={styles.wrapper}></div>
            <Box>
                {lines &&
                    <Typist key={`term-${terminal.module}`}
                        delayGenerator={(mean, std, {line, lineIdx, charIdx, defDelayGenerator})=>{
                            if (lineIdx === 1 && charIdx === line.length - 1) {
                                setShowLineResult(lines[lineIdx-1].result);
                                return 800;
                            }
                            return defDelayGenerator();
                        }}
                        cursor={{show:true,blink:true,element:'|',hideWhenDone: true}}>  
                        <p className={styles["line-wrapper"]}>
                            {lines.map(line=>(
                                [
                                    line?.delay?<Typist.Delay ms={line.delay} />:"",
                                    <span>{line.text}</span>,
                                    <br/>
                                ]
                            ))}
                        </p>
                    </Typist>
                }
                {showLineResult && 
                    <Typist key={`term-res-${terminal.module}`} avgTypingDelay={0} stdTypingDelay={0}
                        onTypingDone={showInfoHandler}
                        cursor={{show:false}}>                     
                        <p>
                            {showLineResult?.map(line=>(
                                [
                                    <span>{line.text}</span>,
                                    <br/>
                                ]
                            ))}
                        </p>
                        <Typist.Delay ms={700} />
                    </Typist>
                }
                {showInfo && 
                    <>
                    <div key={`term-pers-${terminal.module}`}>
                        <p className={styles.divider}/>
                        {!mobile &&
                            <>
                                <p className={styles.small}>&nbsp;██████&nbsp;██&nbsp;&nbsp;&nbsp;██&nbsp;&nbsp;█████&nbsp;&nbsp;██&nbsp;███&nbsp;&nbsp;&nbsp;&nbsp;██&nbsp;██&nbsp;&nbsp;&nbsp;██&nbsp;&nbsp;██████&nbsp;&nbsp;██&nbsp;&nbsp;&nbsp;&nbsp;██&nbsp;███&nbsp;&nbsp;&nbsp;&nbsp;██&nbsp;██████&nbsp;&nbsp;███████</p>
                                <p className={styles.small}>██&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;██&nbsp;&nbsp;&nbsp;██&nbsp;██&nbsp;&nbsp;&nbsp;██&nbsp;██&nbsp;████&nbsp;&nbsp;&nbsp;██&nbsp;██&nbsp;&nbsp;&nbsp;██&nbsp;██&nbsp;&nbsp;&nbsp;&nbsp;██&nbsp;██&nbsp;&nbsp;&nbsp;&nbsp;██&nbsp;████&nbsp;&nbsp;&nbsp;██&nbsp;██&nbsp;&nbsp;&nbsp;██&nbsp;██&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                <p className={styles.small}>██&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;███████&nbsp;███████&nbsp;██&nbsp;██&nbsp;██&nbsp;&nbsp;██&nbsp;███████&nbsp;██&nbsp;&nbsp;&nbsp;&nbsp;██&nbsp;██&nbsp;&nbsp;&nbsp;&nbsp;██&nbsp;██&nbsp;██&nbsp;&nbsp;██&nbsp;██&nbsp;&nbsp;&nbsp;██&nbsp;███████</p>
                                <p className={styles.small}>██&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;██&nbsp;&nbsp;&nbsp;██&nbsp;██&nbsp;&nbsp;&nbsp;██&nbsp;██&nbsp;██&nbsp;&nbsp;██&nbsp;██&nbsp;██&nbsp;&nbsp;&nbsp;██&nbsp;██&nbsp;&nbsp;&nbsp;&nbsp;██&nbsp;██&nbsp;&nbsp;&nbsp;&nbsp;██&nbsp;██&nbsp;&nbsp;██&nbsp;██&nbsp;██&nbsp;&nbsp;&nbsp;██&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;██</p>
                                <p className={styles.small}>&nbsp;██████&nbsp;██&nbsp;&nbsp;&nbsp;██&nbsp;██&nbsp;&nbsp;&nbsp;██&nbsp;██&nbsp;██&nbsp;&nbsp;&nbsp;████&nbsp;██&nbsp;&nbsp;&nbsp;██&nbsp;&nbsp;██████&nbsp;&nbsp;&nbsp;██████&nbsp;&nbsp;██&nbsp;&nbsp;&nbsp;████&nbsp;██████&nbsp;&nbsp;███████</p>
                                <p className={`${styles.divider} ${styles.small} ${styles.title}`}/>
                            </>
                        }
                        <p className={styles.title}>[PROJECT INFO]</p>
                        <p>Network ... | <b>[Ethereum]</b></p>
                        <p>Supply ... | ...<b>[8,200]</b></p>
                        <p>Price ... | .....<b>[TBD]</b></p>
                        <p>Presale Date ... | .....<b>[TBD]</b></p>
                        <p>Public Sale Date ... | .....<b>[TBD]</b></p>
                        <p className={styles.divider}/>
                        <p className={styles.title}>[TEAM]</p>
                        <p>Brand Manager ...  | <b>[<a href="https://twitter.com/BeardedDonutETH" target="_blank" rel="noreferrer">BeardedDonut</a>]</b></p>
                        <p>Project Manager ... | .....<b>[<a href="https://twitter.com/QueenRusskiy" target="_blank" rel="noreferrer">Russkiy</a>]</b></p>
                        <p>Developer ... | ........<b>[<a href="https://twitter.com/bitsm" target="_blank" rel="noreferrer">Bits</a>]</b></p>
                        <p>Artist / Producer ... | .<b>[<a href="https://twitter.com/_matthewtyler" target="_blank" rel="noreferrer">TylerVaughn</a>]</b></p>
                        <p>Creative Director ... | ......<b>[<a href="https://twitter.com/Rebornleghorn" target="_blank" rel="noreferrer">Reborn</a>]</b></p>
                        <p className={styles.divider}/>
                        <p className={styles.title}>[Socials]</p>
                        <p><a href="https://twitter.com/ChainHounds" target="_blank" rel="noreferrer">Twitter ...  | ..<b>[<i className="fab fa-twitter"/> @ChainHounds]</b></a></p>
                        <p><a href="#!" target="_blank" rel="noreferrer">Discord ... | <b>[<i className="fab fa-discord" /> Check Twitter!]</b></a></p>
                        <p><a href="#!" rel="noreferrer">Opensea ... | ..............<b>[TBD]</b></a></p>
                        <p><a href="#!" rel="noreferrer">Looksrare ... | ..............<b>[TBD]</b></a></p>
                    </div>
                    <Terminal
                        commands={APIUtils.consoleCommands()}
                        welcomeMessage={false}
                        promptLabel={'$'}
                        className={styles["terminal-wrapper"]}
                        inputAreaClassName={styles["terminal-input"]}
                        messageClassName={styles.terminal}
                        promptLabelClassName={styles["terminal-prompt"]}
                        inputTextClassName={styles["terminal-prompt"]}
                        inputClassName={styles["terminal-prompt"]}
                        autoFocus
                        errorText="ai@chnft - I don't understand, type help."
                        noDefaults
                        dangerMode={true}
                    />
                    </>
                }
            </Box>
        </Drawer>
        </>
    );
}