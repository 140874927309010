import React,{useState,useEffect} from 'react';
import {List,ListItem,ListItemText,Button,Drawer,Menu as MUIMenu,MenuItem,useMediaQuery} from '@mui/material';

import styles from './Menu.module.css';

export const Menu = (props) =>{
    const mobile=useMediaQuery('(max-width:1201px)');
    const sortabig=useMediaQuery('(max-width:1600px)');
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(()=>{
        if (mobile===true) setOpen(Boolean(anchorEl));
        else setOpen(!sortabig);
    },[mobile,sortabig,anchorEl]);
    
    const clickHandler = (e) => {
        setOpen(true);
        if (e.currentTarget) setAnchorEl(e.currentTarget);
    }

    const closeHandler = () => {
        setOpen(!open);
        setAnchorEl(null);
    }

    if (mobile){
        return (
            <>
                <Button
                    id="faction-menu-button"
                    aria-controls={open ? 'faction-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="outlined"
                    className={styles["menu-button"]}
                    disableElevation
                    onClick={clickHandler}
                    endIcon={<i className={`${styles.icon} far fa-chevron-down`} />}
                >
                     {props.items.items.filter(a=>a.id===props.selected)[0].name || props.items.name}
                </Button>
                <MUIMenu id="faction-menu"
                    PopoverClasses={{paper: styles["menu-container"]}}
                    MenuListProps={{'aria-labelledby': 'faction-menu-button'}}
                    anchorEl={anchorEl} 
                    open={open} 
                    onClose={closeHandler}                
                    elevation={0} 
                    anchorOrigin={{vertical: 'top',horizontal: 'left'}} 
                    transformOrigin={{vertical: 'top',horizontal: 'left'}}
                    className={styles["menu-container"]}
                >
                    {props.items.items.map(faction=>(
                        <MenuItem key={`faction-list-${faction.key}`} disableRipple onClick={()=>{props.click(faction.id);closeHandler();}} selected={props.selected === faction.id}>
                            {faction.name}
                            <span className={styles.small}>{faction.secondary}</span>
                        </MenuItem>
                    ))}
                </MUIMenu>
            </>
        );
    } else {
        return (
            <Drawer variant="permanent" open={open} classes={{paper:styles.drawer}}>
                <List classes={{root:styles.menu}}>
                    <ListItem button onClick={closeHandler}>
                        <i className="far fa-bars"/>
                    </ListItem>
                    {props.items.items.map(faction=>(
                        <ListItem button key={`faction-list-${faction.key}`} onClick={()=>props.click(faction.id)} className={props.selected===faction.id?styles.selected:null}>
                            {!open && 
                                <ListItemText primary={faction.name.substr(0,1)} />
                            }
                            {open &&
                                <ListItemText primary={faction.name} secondary={faction.secondary}/>
                            }
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        );
    }
}