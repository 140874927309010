import React,{useState,useEffect} from 'react';
import { useDispatch/*,useSelector*/ } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { useConnect,useAccount,useDisconnect,useEnsName } from 'wagmi';
import {Button,Grid,Menu,MenuItem,useMediaQuery} from '@mui/material';

import MintOG from './MintOG';

import styles from './Tiles.module.css';
import './masonry.css';

import APIMetaMask from '../../../api/MetaMask';
import APIUsers from '../../../api/Users';
import APIUtils from '../../../api/Utils';
import * as actions from '../../../store/actions';

export const Tiles = (props) =>{
	const navigate=useNavigate();
	const dispatch=useDispatch();
    const mobile=useMediaQuery('(max-width:991px)');

    //const user = useSelector(state => state.auth);
    //const terminal = useSelector(state => state.console);
    const [isOG,setIsOG]=useState(false);
    const [mintedOG,setMintedOG]=useState(0);
    const [ogCoupon,setOgCoupon]=useState();    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const { address, isConnected } = useAccount();
    const { data: ensName } = useEnsName({ address });
    const { connectAsync, connectors, error, isLoading, pendingConnector } = useConnect();
    const { disconnect } = useDisconnect();

	const moduleClickHandler = (mod) =>{
		dispatch(actions.console(mod));
	}

    const connectClickHandler = async (e,connect) =>{
        e.preventDefault();
        const data=await connect();
        if (data){
            try{
                const res2=await APIMetaMask.wagmiFormat(data);
                if (res2.data){
                    dispatch(actions.authSuccess(res2.data));
                } else dispatch(actions.snackbarShow(res2.error));
            } catch(e){
                console.log(e);
            }
        }
    }

    const openHandler = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const closeHandler = () => {
        setAnchorEl(null);
    }

    useEffect(()=>{
        const _checkOG=async (address)=>{
            try{
                const res=await APIUsers.og.get({wallet:address});
                if (res.count>0){
                    setIsOG(true);
                    dispatch(actions.ogCoupon(JSON.parse(res.data.coupon)));
                    setOgCoupon(JSON.parse(res.data.coupon));
                    try{
                        const res2=await APIUsers.og.minted({wallet:address});
                        if (res2?.data?.total) setMintedOG(res2.data.total);
                    } catch(e){
                        console.log(e);
                    }
               } else {
                    dispatch(actions.ogCoupon(null));
                    setOgCoupon(null);
                    setIsOG(false);
                }
            } catch(e){
                console.log(e);
            }
        }
        if (isConnected===true && address){
            _checkOG(address);
        }

        return ()=>{
            setIsOG(false);
            setMintedOG(0);
            setAnchorEl(null);
            setOgCoupon(null);
        }
    },[address,isConnected,dispatch]);

    useEffect(()=>{
        if (error) dispatch(actions.snackbarShow(error?.message ?? 'Failed to connect'));
    },[error,dispatch]);


    //if (!terminal.loaded) return "";

    return (
        <Grid container className={styles.wrapper}>
            <Grid item xs={12}>
                <div className={`${styles["new-wrapper"]}`}>
                    <div className={`${styles["new-content"]} ${styles.cyan}`}>
                        <div className={styles.content}>
                            <h2 className={styles.title}>ETH // WALLET</h2>
                            {isConnected && 
                                <>
                                    <p>
                                        You are connected to the ChainHounds Interface: <br/>
                                        Survivor {ensName ? `${ensName}` : APIUtils.maskWallet(address)}
                                    </p>
                                    <Button variant="outlined" className={`${styles["mint-button"]} ${styles.small}`} onClick={disconnect}>
                                        Disconnect
                                    </Button>
                                    {isOG && mintedOG===0 && ogCoupon &&
                                        <MintOG coupon={ogCoupon}/>
                                    }
                                    {isOG && mintedOG>0 &&
                                        <h2 className={styles.h2}>OG Pass Claimed!</h2>
                                    }
                                </>
                            }
                            
                            {!isConnected && 
                                <>
                                    <p>Connect your Ethereum Wallet to the ChainHounds Interface.</p>
                                    <Button variant="outlined" className={styles["mint-button"]} onClick={openHandler}>
                                        Connect Wallet
                                    </Button>
                                    <Menu id="connect-menu" anchorEl={anchorEl} open={open} onClose={closeHandler} 
                                        classes={{paper:styles.menu}} 
                                        MenuListProps={{'aria-labelledby': 'connect-button',sx: { width: anchorEl && anchorEl.offsetWidth }}}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}>
                                        {connectors.map(connector => (
                                            <MenuItem className={styles["menu-item"]} disabled={!connector.ready} key={connector.id} onClick={(e) =>connectClickHandler(e,()=>connectAsync({connector}))}>
                                                {connector.name}
                                                {isLoading && connector.id === pendingConnector?.id && ' (connecting)'}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                    <p className={styles.tos}>
                                        By connecting your wallet your agree to our <Link to="/terms">TOS</Link>
                                    </p>
                                </>
                            }
                        </div>
                    </div>
                    <div className={`${styles["new-border"]}`}/>
                </div>
                <div className={`${styles["new-wrapper"]}`}>
                    <div className={`${styles["new-content"]} ${styles.clickable}`} onClick={()=>{
                            moduleClickHandler("game");
                            navigate("/game",{state:{module:"game"}});
                        }}>
                        <h1>Game</h1>
                    </div>
                    <div className={`${styles["new-border"]}`}/>
                </div>
                <div className={`${styles["new-wrapper"]}`}>
                    <div className={`${styles["new-content"]} ${styles.clickable}`} onClick={()=>{
                            moduleClickHandler("lore");
                            navigate("/lore",{state:{module:"lore"}});
                        }}>
                        <h1>Lore</h1>
                        {/*<div className={styles.img} style={{backgroundImage:`url(${require('../../../assets/images/lore1.png').default})`}}/>*/}
                    </div>
                    <div className={`${styles["new-border"]}`}/>
                </div>
                {mobile===true &&
                    <div className={`${styles["new-wrapper"]}`}>
                        <div className={`${styles["new-content"]} ${styles.clickable}`} onClick={()=>{
                                moduleClickHandler("design");
                                navigate("/design",{state:{module:"design"}});
                            }}>
                            <h1>Design</h1>
                            {/*<div className={styles.img} style={{backgroundImage:`url(${require('../../../assets/images/blueprint.png').default})`}}/>*/}
                        </div>
                        <div className={`${styles["new-border"]}`}/>
                    </div>
                }
                <div className={`${styles["new-wrapper"]}`}>
                    <div className={`${styles["new-content"]} ${styles.clickable}`} onClick={()=>{
                            moduleClickHandler("roadmap");
                            navigate("/roadmap",{state:{module:"roadmap"}});
                        }}>
                        <h1>Rd-Map</h1>
                        {/*<div className={styles.img} style={{backgroundImage:`url(${require('../../../assets/images/loot.png').default})`,backgroundPositionY:"-340px"}}/>*/}
                    </div>
                    <div className={`${styles["new-border"]}`}/>
                </div>
            </Grid>
        </Grid>
    );
}