import * as actionTypes from '../actions/actionTypes';

const initialState = {
    module: null,
    loaded:false
};

const console = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.CONSOLE_LOADED:
        return {
          ...state,
          loaded: action.data,
        };
      case actionTypes.CONSOLE_MODULE:
        return {
          ...state,
          module: action.data,
        };
      default:
        return state;
    }
};
  
export default console;