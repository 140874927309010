import React,{useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { ethers } from 'ethers';
import {useAccount,usePrepareContractWrite,useContractWrite,useWaitForTransaction} from 'wagmi';
import {Button} from '@mui/material';

import * as actions from '../../../../store/actions';

import tstyles from '../Tiles.module.css';
import styles from './MintOG.module.css';

export function MintOG(props) {
    const { REACT_APP_CONTRACT_ADDRESS } = process.env;
    const dispatch = useDispatch();
    const { address } = useAccount();

    const {config, error: prepareError, isError: isPrepareError} = usePrepareContractWrite({
        chainId: 1, //1=mainnet, 3=ropsten, 4=rinkeby, 5=goerli, 42=kovan
        addressOrName: REACT_APP_CONTRACT_ADDRESS,
        contractInterface: ['function mint(uint256,tuple(bytes32,bytes32,uint8)) payable returns (uint256)'],
        functionName: 'mint',
        args: [1,[props.coupon.r,props.coupon.s,props.coupon.v]],
        overrides: {
            from: address,
            value: ethers.BigNumber.from(0).toHexString(),
        },
    });
    const { data, error, isError, write } = useContractWrite(config);
    const { isLoading, isSuccess } = useWaitForTransaction({hash: data?.hash});

    useEffect(() => {
        if (isError) dispatch(actions.snackbarShow(error.message));
        if (isPrepareError) console.log(prepareError.message);
    },[prepareError,error,isPrepareError,isError,dispatch]);

    return (
        <div>
            {!isSuccess &&
                <Button variant="outlined" disabled={!write || isLoading} className={`${tstyles["mint-button"]} ${tstyles["og-pass"]}`} onClick={() => write?.()}>
                    {isLoading ? 'Minting OG Pass...' : 'Mint OG Pass'}
                </Button>
            }
            {isSuccess && (
                <div>
                    <h2 className={styles.h2}>OG Pass Claimed!</h2>
                    <a className={styles.a} target="_blank" rel="noreferrer" href={`https://etherscan.io/tx/${data?.hash}`}>Check on Etherscan</a>.
                </div>
            )}
        </div>
    );
}