import React,{useState} from 'react';
import {Grid} from '@mui/material';
import Part from './Part';

import styles from './Hound.module.css';

export const Hound = () =>{

    const [putTogether,setPutTogether]=useState(true);
    const [part,setPart]=useState();
    const [description,setDescription]=useState();

    const clickHandler=()=>{
        if (!part) setPutTogether(!putTogether);
        setPart(null);
    }
    
    const partClickHandler=(active_part)=>{
        //if (active_part) active_part=null;
        switch (active_part){
            case "ear/antenna":
                setDescription(
                    <span>
                        Capable of detecting sound as low as -200dB due to the internal ceramic coating and digital amplification,
                        as well as having a wide frequency range, a hound's ear far surpasses human hearing capability. 
                        The antenna can also transmit and intercept a large range of radio frequencies.
                    </span>
                );
                break;
            case "head":
                setDescription(
                    <span>
                        Carrying a vast array of customizable optical, audio, magnetic, tactile, and even aroma detection, a
                        ChainHound's head is also home to a miniature black box where a backup of data is preserved.
                    </span>
                );
                break;
            case "chest/torso":
                setDescription(
                    <span>
                        Built with weight and strength in mind, the Chest / Torso are surprisingly resilient to heat, 
                        ballistics, and chemical damage. This is achieved through layered nano-fiber technology. Some models 
                        feature thicker coatings, depending on the hound's intended usage.
                    </span>
                );
                break;
            case "neck":
                setDescription(
                    <span>
                        Provides 360-degree movement for the head, with some models even having a series of backup sensory 
                        equipment at the neck's base, allowing a hound to continue at nearly full functioning capability, 
                        even if the head and most of the neck are destroyed.
                    </span>
                );
                break;
            case "pelvic stabilizer":
                setDescription(
                    <span>
                       The pelvic stabilizer gives a ChainHound much of its agility. Dispensing weight appropriately as 
                       terrain and momentum required. Hounds are actually able to function in an upright mode, allowing them 
                       to walk even if both front legs are not functional.
                    </span>
                );
                break;
            case "tail":
                setDescription(
                    <span>
                       Having several sensory and communication capabilities in common with the head, some hounds even 
                       feature a "Droppable tail" which was often used for intel gathering and reconnaissance. Many models even 
                       featured housing for weapons on a ChainHound's tail.
                    </span>
                );
                break;
            case "hip gyro":
                setDescription(
                    <span>
                       Working in conjunction with the pelvic stabilizer, the hip gyro is a key component that allows a hound 
                       to maintain balance during movement. It is also crucial to the "self correcting" capabilities of a 
                       damaged hound.
                    </span>
                );
                break;
            case "mid leg":
                setDescription(
                    <span>
                       A nearly perfect combination of lightweight, strong and flexible. Most hound legs are designed for easy 
                       reconfiguration to allow easy swapping after damage, a "swim kit" is even available that provides better 
                       movement in water or mud.
                    </span>
                );
                break;
            case "high suspension":
                setDescription(
                    <span>
                      Using an onboard system to control the vertical movement of the hound's legs relative to the chassis, 
                      rather than the passive suspension provided by large springs where the movement is determined entirely 
                      by the surface. Some lightweight models of ChainHounds are said to be able to sustain a fall from 
                      almost any height, with little or no damage.
                    </span>
                );
                break;
            case "high traction":
                setDescription(
                    <span>
                      A wide range of traction options are available, some even provide pneumatic suction allowing a hound to 
                      climb vertical or inverted smooth terrain such as ice or glass.
                    </span>
                );
                break;
            case "shoulder plate":
                setDescription(
                    <span>
                      Often used to house additional armor protection, the shoulder plates also serve a key role in reducing 
                      the number of environmental contaminants that enter into a ChainHound's chassis and the inner components.
                    </span>
                );
                break;
            default:
                setDescription(null);
                break;
        }
        setPart(active_part);
        setPutTogether(false);
    }

	return (
        <Grid container spacing={0} onClick={clickHandler} style={{marginTop:0}} >
            <Grid item xs={12} lg>
                <div className={`${styles.hound} ${putTogether?styles.join:styles.animate}`}>
                    <div className={`${styles.hero}`}/>
                    <Part type="ear/antenna" join={putTogether} active={part} click={()=>partClickHandler("ear/antenna")} />
                    <Part type="head" join={putTogether} active={part} click={()=>partClickHandler("head")} />
                    <Part type="chest/torso" join={putTogether} active={part} click={()=>partClickHandler("chest/torso")} />
                    <Part type="neck" join={putTogether} active={part} click={()=>partClickHandler("neck")} />
                    <Part type="pelvic stabilizer" join={putTogether} active={part} click={()=>partClickHandler("pelvic stabilizer")} />
                    <Part type="tail" join={putTogether} active={part} click={()=>partClickHandler("tail")} />
                    <Part type="hip gyro" join={putTogether} active={part} click={()=>partClickHandler("hip gyro")} />
                    <Part type="mid leg" join={putTogether} active={part} click={()=>partClickHandler("mid leg")} />
                    <Part type="high suspension" join={putTogether} active={part} click={()=>partClickHandler("high suspension")} />
                    <Part type="high traction" join={putTogether} active={part} click={()=>partClickHandler("high traction")} />
                    <Part type="shoulder plate" join={putTogether} active={part} click={()=>partClickHandler("shoulder plate")} />
                </div>
            </Grid>
            <Grid item xs={12} lg={4}>
                {part && 
                    <div className={styles.tip}>
                        <h1>{part}</h1>
                        {description &&
                            <p>{description}</p>
                        }
                    </div>
                }
            </Grid>
        </Grid>
	);
}